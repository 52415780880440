import {
  faCheck,
  faChevronDown,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { useState } from "react";
import { useWatch } from "react-hook-form";

import ClientSummary from "./ClientSummary";
import CustomForm from "./CustomForm.js";
import DentalChart from "./DentalChart.js";
import { KeyPatientInfoFormFields } from "./KeyPatientInfoFormFields.js";
import { NoteHeader } from "./NoteHeader.js";
import SOAPForm from "./SOAPForm.js";
import Twemoji from "../../../../Twemoji.js";
import { AttachmentsPreview } from "../../../attachments/AttachmentsPreview.js";
import HeadingWrapper from "../../../common/HeadingWrapper.js";
import AttachmentSelection from "../../../common/inputs/AttachmentSelection.js";
import { EmailExportForm } from "../../../email/EmailExportForm.js";
import PromptFeedbackWidget from "../../common/PromptFeedbackWidget.js";
import { ExportNoteInline } from "../../ExportNoteInline.js";

export function NoteForm({
  note,
  isQuicknote,
  isSuperSOAP,
  isTemplate = false,
  isScribeEdit,
  getValues,
  patientUUID,
  control,
}) {
  const [exportExpanded, setExportExpanded] = useState(false);
  const shouldUseCustomForm = useWatch({
    control,
    name: "shouldUseCustomForm",
  });
  const { simpleClientSummariesBeta } = useFlags();

  const isDentalChart = note?.jobType === "dental_record";
  const shouldShowClientSummaryAccordion =
    note?.jobTypeName === "Medical Record" &&
    simpleClientSummariesBeta;
  return (
    <div className="mb-5">
      <NoteHeader
        note={note}
        control={control}
        isTemplate={isTemplate}
        isQuicknote={isQuicknote}
        isSuperSOAP={isSuperSOAP}
        isScribeEdit={isScribeEdit}
        patientUUID={patientUUID}
      />

      <div className="mb-5">
        {shouldUseCustomForm ? (
          <div className="flex flex-col md:flex-row md:items-center md:space-x-10 space-y-10 md:space-y-0">
            <CustomForm control={control} />
            {isDentalChart && (
              <div className="w-[300px] md:w-[400px]">
                <DentalChart
                  record={note?.customFormFields[0].textValue}
                />
              </div>
            )}
          </div>
        ) : (
          <SOAPForm control={control} note={note} />
        )}
      </div>

      {note?.jobType !== "dictation" && note?.isAutoSoap && (
        <div className="relative h-12 mb-6">
          <div
            style={{
              boxShadow: "0px 0px 2px 3px rgba(99, 102, 241, 0.3)",
            }}
            className="absolute w-full h-full animate-pulse rounded-xl bg-transparent hover:animate-none pointer-events-none"
          ></div>
          <PromptFeedbackWidget
            additionalClasses={"mb-6"}
            jobType={note?.jobType}
            noteUuid={note?.uuid}
            userUuid={note?.user?.uuid}
            feedback={note?.feedback}
            thumbsUp={note?.thumbsUp}
          />
        </div>
      )}

      <div className="py-5">
        {!isScribeEdit && !isTemplate && (
          <div className="flex flex-col space-y-2">
            {note?.patient && (
              <div id="additionalInfo">
                <Accordion
                  square={false}
                  defaultExpanded={false}
                  disableGutters={true}
                  sx={{
                    "&::before": {
                      backgroundColor: "transparent",
                    },
                    boxShadow: "0px 0px 3px 0px rgba(0,0,0,0.1)",
                    borderRadius: "5px",
                    backgroundColor: "rgba(255,255,255,0.05)",
                    marginTop: "10px",
                  }}
                >
                  <AccordionSummary
                    expandIcon={
                      <FontAwesomeIcon
                        className="dark:text-gray-300"
                        icon={faChevronDown}
                      />
                    }
                  >
                    <div>
                      <h3 className=" text-gray-600 dark:text-gray-300">
                        <Twemoji emoji="➕" /> Additional Key
                        Information
                      </h3>
                      <h5 className="text-gray-400 text-sm">
                        If you would like to include additional key
                        information regarding this patient, type it in
                        below and it will be added to their notebook.
                      </h5>
                    </div>
                  </AccordionSummary>

                  <AccordionDetails>
                    <KeyPatientInfoFormFields
                      control={control}
                      keyHistory={note?.keyHistory}
                      keyMedications={note?.keyMedications}
                      vaccinationStatus={note?.vaccinationStatus}
                      weight={note?.weight}
                    />
                  </AccordionDetails>
                </Accordion>
              </div>
            )}

            <div id="addAttachments">
              <Accordion
                square={false}
                defaultExpanded={false}
                disableGutters={true}
                sx={{
                  "&::before": {
                    backgroundColor: "transparent",
                  },
                  boxShadow: "0px 0px 3px 0px rgba(0,0,0,0.1)",
                  borderRadius: "5px",
                  backgroundColor: "rgba(255,255,255,0.05)",
                  marginTop: "10px",
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <FontAwesomeIcon
                      className="dark:text-gray-300"
                      icon={faChevronDown}
                    />
                  }
                >
                  <div>
                    <h3 className=" text-gray-600 dark:text-gray-300">
                      <Twemoji emoji="📎" /> Add Attachments
                    </h3>
                    <h5 className="text-gray-400 text-sm">
                      Accepted file types: pictures, videos, PDFs,
                      documents.
                    </h5>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div id="addAttachments" className="my-2">
                    <AttachmentSelection />
                  </div>
                  {note?.attachments && (
                    <AttachmentsPreview
                      attachments={note?.attachments}
                      isCompactView={true}
                      attachmentPreviewText={
                        "Files attached to this note"
                      }
                      emptyStateText={
                        "No files are currently attached to this note."
                      }
                    />
                  )}
                </AccordionDetails>
              </Accordion>
            </div>

            <div id="noteEmailSection">
              <Accordion
                onChange={(expanded) => setExportExpanded(expanded)}
                square={false}
                defaultExpanded={false}
                disableGutters={true}
                sx={{
                  "&::before": {
                    backgroundColor: "transparent",
                  },
                  boxShadow: "0px 0px 3px 0px rgba(0,0,0,0.1)",
                  borderRadius: "5px",
                  backgroundColor: "rgba(255,255,255,0.05)",
                  marginTop: "10px",
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <FontAwesomeIcon
                      className="dark:text-gray-300"
                      icon={faChevronDown}
                    />
                  }
                >
                  <div>
                    <h3 className=" text-gray-600 dark:text-gray-300">
                      <Twemoji emoji="📤" /> Export Note
                    </h3>
                    <h5 className="text-gray-400 text-sm">
                      You can copy, download, or email this note.
                    </h5>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <>
                    <HeadingWrapper
                      heading={<h4>Quick Export Options</h4>}
                    ></HeadingWrapper>
                    <ExportNoteInline
                      getValues={getValues}
                      exportExpanded={exportExpanded}
                      note={note}
                    />
                    <HeadingWrapper
                      heading={<h4>Email Exporting</h4>}
                    ></HeadingWrapper>
                    <div className="my-4">
                      <EmailExportForm control={control} />
                    </div>

                    <h5 className="text-indigo-400 dark:text-indigo-400 mt-4 text-xs md:text-sm">
                      <FontAwesomeIcon icon={faInfoCircle} /> Note
                      that this email will be automatically sent to
                      these email addresses, plus the Auto Export
                      email address (if you have set one up) when you
                      click <FontAwesomeIcon icon={faCheck} />{" "}
                      <strong>Submit Note.</strong>
                    </h5>
                  </>
                </AccordionDetails>
              </Accordion>
            </div>

            {shouldShowClientSummaryAccordion && (
              <div
                id="clientSummary"
                data-cy="client-summary-accordion"
              >
                <Accordion
                  square={false}
                  defaultExpanded={false}
                  disableGutters={true}
                  sx={{
                    "&::before": {
                      backgroundColor: "transparent",
                    },
                    boxShadow: "0px 0px 3px 0px rgba(0,0,0,0.1)",
                    borderRadius: "5px",
                    backgroundColor: "rgba(255,255,255,0.05)",
                    marginTop: "10px",
                  }}
                >
                  <AccordionSummary
                    expandIcon={
                      <FontAwesomeIcon
                        className="dark:text-gray-300"
                        icon={faChevronDown}
                      />
                    }
                  >
                    <div>
                      <h3 className="text-gray-600 dark:text-gray-300 flex flex-row items-center">
                        <Twemoji emoji="🏠" />{" "}
                        <span className="ml-1">Client Summary</span>
                      </h3>

                      <h5 className="text-gray-400 text-sm">
                        Automatically generate a summary for your
                        client based on your note!
                      </h5>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ClientSummary note={note} />
                  </AccordionDetails>
                </Accordion>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
