import { gql, useMutation, useQuery } from "@apollo/client";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { FormProvider } from "react-hook-form";
import { useTransition, animated, config } from "react-spring";

import { AccountItemBlock, AccountItemLine } from "./AccountItem";
import { APPOINTMENT_OBJECTIVE_NORMAL_DEFAULTS } from "../../constants";
import { getSavedField } from "../../hooks/use-default-values";
import { alert } from "../common/Alert";
import { BasicButton } from "../common/buttons/BasicButton";
import ToggleSwitch from "../common/buttons/ToggleSwitch";
import ObjectiveFieldItem from "../notes/common/ObjectiveFieldItem";

const GET_OBJECTIVE_NORMAL_DEFAULTS = gql`
  {
    user {
      objectiveNormalDefaults {
        uuid
        shouldUse
        temperature
        heartRate
        weight
        respiratoryRate
        capillaryRefillTime
        hydrationStatus
        bodyConditionScore
        painScore
        fearAnxietyStressScore
        eyes
        ears
        oralDental
        cardiovascular
        respiratory
        neurological
        musculoskeletal
        lymphatic
        gastrointestinal
        reproductiveUrinary
        furSkin
        nasal
        masses
        mucousMembranes
      }
    }
  }
`;

const EDIT_OBJECTIVE_NORMAL_DEFAULTS = gql`
  mutation editObjectiveNormalDefaults(
    $objectiveNormalDefaultsInput: ObjectiveNormalDefaultsInput!
  ) {
    editObjectiveNormalDefaults(
      objectiveNormalDefaultsInput: $objectiveNormalDefaultsInput
    ) {
      ok
    }
  }
`;

function getObjectiveNormalDefaultsFieldArray(
  objectiveNormalDefaults,
) {
  let result = [];

  if (!objectiveNormalDefaults) {
    return [];
  }

  APPOINTMENT_OBJECTIVE_NORMAL_DEFAULTS.forEach(
    ({ name, label, type }) => {
      if (!objectiveNormalDefaults[name]) {
        // This is a new note or a new template
        result.push({
          name,
          value: "",
          label,
          type,
        });
      } else {
        result.push({
          name,
          value: getSavedField(name, objectiveNormalDefaults[name]),
          label,
          type,
        });
      }
    },
  );

  return result;
}

export function ObjectiveNormalDefaultsForm() {
  const methods = useForm();
  const { control, getValues, setValue, reset } = methods;
  const { useGptUnstableObjectiveDefaults } = useFlags();
  const { data, refetch } = useQuery(GET_OBJECTIVE_NORMAL_DEFAULTS);
  const [shouldUse, setShouldUse] = useState(
    data?.user?.objectiveNormalDefaults?.shouldUse,
  );

  useEffect(() => {
    setShouldUse(data?.user?.objectiveNormalDefaults?.shouldUse);
  }, [data]);

  useEffect(() => {
    reset({
      objectiveNormalDefaultsFieldArray: [
        ...getObjectiveNormalDefaultsFieldArray(
          data?.user?.objectiveNormalDefaults,
        ),
      ],
    });
  }, [data]);

  const popUp = useTransition(shouldUse, {
    from: { opacity: 0, y: -50 },
    enter: { opacity: 1, y: 0 },
    leave: { opacity: 0, y: -50 },
    config: config.gentle,
  });

  const [editObjectiveNormalDefaults] = useMutation(
    EDIT_OBJECTIVE_NORMAL_DEFAULTS,
  );

  return (
    <FormProvider {...methods}>
      <AccountItemBlock
        title={
          <>
            <span>Custom Objective Defaults:</span>
          </>
        }
        desc={
          <>
            <span>
              <br />
              Turn this ON if you want to set custom default phrases
              for your objective section: <br />
              <br />
              1. This will convert simple phrases like 'normal,'
              'clear,' or 'looks good' to your preferred default
              phrase
              <br />
              <br />
              2. If you don't mention a particular system, it will be
              set to your preferred default phrase.
              <br />
              <br />
              For example, if you don&apos;t want Scribenote to say
              "Not Mentioned" for your cardiovascular section, but
              rather, "Strong and steady pulse, no murmurs or
              arrhythmias", you can set that here.
              <br /> <br />
              <b>IMPORTANT NOTE:</b> If you turn this ON and don't set
              any defaults, the default will be "Normal on Exam".
            </span>
            <span></span>
          </>
        }
      >
        <div className="bg-gray-50 dark:bg-gray-900/30 shadow-sm rounded-lg p-3">
          <AccountItemLine>
            <div className="flex flex-row items-center space-x-2">
              <span>Use Custom Objective Defaults</span>
              <Controller
                control={control}
                rules={{
                  required: false,
                }}
                render={() => (
                  <ToggleSwitch
                    toggleState={shouldUse}
                    setToggleState={setShouldUse}
                    toggleCallback={() => {
                      editObjectiveNormalDefaults({
                        variables: {
                          objectiveNormalDefaultsInput: {
                            shouldUse: !shouldUse,
                          },
                        },
                      });
                      refetch();
                    }}
                  />
                )}
                name="shouldUse"
                value={shouldUse}
              />
            </div>
          </AccountItemLine>

          {shouldUse
            ? popUp(
                (styles, item) =>
                  item && (
                    <animated.div style={styles}>
                      {APPOINTMENT_OBJECTIVE_NORMAL_DEFAULTS.map(
                        (field, index) => {
                          // fields that are part of the GPT unstable defaults are not gonna be shown to everybody else for now
                          const excludedFields = [
                            "nasal",
                            "masses",
                            "mucousMembranes",
                          ];
                          if (
                            excludedFields.includes(field.name) &&
                            !useGptUnstableObjectiveDefaults
                          ) {
                            return null;
                          }
                          return (
                            <ObjectiveFieldItem
                              key={field.name}
                              fieldName={field.name}
                              fieldLabel={field.label}
                              formFieldValue={`objectiveNormalDefaultsFieldArray[${index}].value`}
                              control={control}
                              isForSOAPForm={false}
                              placeholder={"Normal on Exam"}
                              setValue={setValue}
                            />
                          );
                        },
                      )}

                      <div className="flex self-end py-4 justify-self-end">
                        <BasicButton
                          onClick={() => {
                            const formValues = getValues();

                            const structuredObjectiveNormalDefaults =
                              {};

                            formValues?.objectiveNormalDefaultsFieldArray.forEach(
                              (field) => {
                                structuredObjectiveNormalDefaults[
                                  field.name
                                ] = field.value;
                              },
                            );

                            editObjectiveNormalDefaults({
                              variables: {
                                objectiveNormalDefaultsInput: {
                                  shouldUse: shouldUse,
                                  ...structuredObjectiveNormalDefaults,
                                },
                              },
                            }).then(() => {
                              alert(
                                "success",
                                "Custom defaults saved!",
                              );
                            });
                            refetch();
                          }}
                        >
                          Save Defaults
                        </BasicButton>
                      </div>
                    </animated.div>
                  ),
              )
            : null}
        </div>
      </AccountItemBlock>
    </FormProvider>
  );
}
