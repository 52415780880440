import { useMutation, gql } from "@apollo/client";
import { faTrash, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFlags } from "launchdarkly-react-client-sdk";
import mixpanel from "mixpanel-browser";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router";

import {
  MODIFY_NOTE,
  CREATE_PATIENT_NOTE,
  CREATE_QUICK_NOTE,
  DELETE_NOTE,
} from "./api/mutations.js";
import { submitBasicNote } from "./api/submit.js";
import { NoteForm } from "./form/NoteForm.js";
import { RecordingTranscriptDrawer } from "./form/RecordingTranscriptDrawer.js";
import { SOAPExtractionDrawer } from "./form/SOAPExtractionDrawer.js";
import { ProcessingNoteFormFallback } from "./ProcessingNoteFormFallback.js";
import MARK_NOTES_EXPORTED from "../../../graphql/mutations/MarkNotesExported.js";
import SEND_EXPORT_NOTE_EMAIL from "../../../graphql/mutations/SendExportNoteEmail.js";
import {
  useDefaultValues,
  useSuperSoap,
  useUploadProgress,
  useAutosave,
  useAutosaveContext,
  FindAndReplaceProvider,
} from "../../../hooks";
import { submitAttachments } from "../../attachments/attachment-utils.js";
import { alert } from "../../common/Alert.js";
import { SaveButton } from "../../common/buttons/SaveButton.js";
import { FloatingWordMultiEditInput } from "../../common/inputs/FloatingWordMultiEditInput.js";
import { ErrorMessage } from "../../common/inputs/Input.js";
import { copyAll } from "../../notebook/note/utils.js";
import { formToNoteInput } from "../common/utils.js";

const UPLOAD_NOTE_ATTACHMENTS = gql`
  mutation uploadNoteAttachments(
    $noteUuid: String!
    $attachments: [AttachmentInput]!
    $createdAt: DateTime!
  ) {
    uploadNoteAttachments(
      noteUuid: $noteUuid
      attachments: $attachments
      createdAt: $createdAt
    ) {
      ok
    }
  }
`;

function EditableNoteFormWrapper(props) {
  const {
    note,
    isSuperSOAP,
    isQuicknote,
    isScribeEdit = false,
    shouldBillUserForNote,
    patientUUID,
    noteUUID,
    shouldShowSubmitButton = true,
    template,
    setShouldShowDeleteNoteModal,
    setIsForDeletedNote,
  } = props;

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isDraftSubmission, setIsDraftSubmission] = useState(false);
  const [shouldShowDateEdit, setShouldShowDateEdit] = useState(false);
  const [shouldShowDeleteConfirm, setShouldShowDeleteConfirm] =
    useState(false);

  const navigate = useNavigate();
  const [modifyNote] = useMutation(MODIFY_NOTE);
  const [deleteNote] = useMutation(DELETE_NOTE);
  const [createPatientNote] = useMutation(CREATE_PATIENT_NOTE);
  const [createQuickNote] = useMutation(CREATE_QUICK_NOTE);
  const [modifyNotes] = useMutation(MARK_NOTES_EXPORTED);
  const [uploadNoteAttachments] = useMutation(
    UPLOAD_NOTE_ATTACHMENTS,
  );
  const [sendExportNoteEmail] = useMutation(SEND_EXPORT_NOTE_EMAIL);
  const { cancelDebounce } = useAutosaveContext();

  const methods = useForm();
  const {
    control,
    getValues,
    setValue,
    reset,
    watch,
    trigger,
    formState: { errors },
    resetField,
  } = methods;

  const { setPercentageUploaded, setCurrentFileName } =
    useUploadProgress();

  const { completeNoteSuperSoap } = useSuperSoap();

  const { cancelDebounceOnSubmit } = useFlags();

  useEffect(() => {
    if (isFormSubmitted) {
      // Submit in here as a sort of "callback"
      // to toggling the isFormSubmitted state.
      // Otherwise, the state updates will not happen on time
      // and the Prompt will trigger on Draft/Submit button click.
      const data = getValues();
      const isDraft = isDraftSubmission;
      onSubmit({
        data,
        isDraft,
      });
    }
  }, [isFormSubmitted]);

  useDefaultValues({
    template,
    note,
    reset,
  });

  useAutosave({
    save: onSubmit,
    getValues,
    watch,
    control,
  });

  async function onSubmit({
    data,
    isDraft = false,
    isAutosave = false,
  }) {
    const props = {
      isSuperSOAP,
      patientUUID,
      noteUUID,
    };
    const mutations = {
      modifyNote,
      createPatientNote,
      createQuickNote,
    };

    if (!isAutosave) {
      setIsFormSubmitted(true);
      mixpanel.track("Note Submitted", {
        noteUUID: noteUUID,
      });
    }

    function inlineEmailExport({ uuid }) {
      if (!isDraft && data?.toEmail) {
        sendExportNoteEmail({
          variables: {
            toEmail: data?.toEmail,
            grantedNoteUuid: uuid,
            isForAssistant: false,
            specialInstructions: data?.specialInstructions,
            areAttachmentsIncluded: data?.includeAttachments,
            shouldEmbedEmailExports:
              data?.exportType === "embeddedText",
          },
        })
          .then(async () => {
            alert("success", `Email sent to ${data?.toEmail}`);
            modifyNotes({
              variables: {
                noteUuids: [uuid],
                action: "mark_exported",
              },
            });
          })
          .catch(() => {
            alert(
              "error",
              "Email export unsuccessful. Please try again.",
            );
          });
      }
    }

    const result = await trigger();

    if (!result) {
      setIsFormSubmitted(false);
      if (isAutosave) {
        return "form-errors";
      }
    } else {
      const structuredNoteData = formToNoteInput({
        data,
        shouldSaveAsGenericObjective: data?.shouldUseGenericObjective,
      });
      if (
        data.keyHistory ||
        data.keyMedications ||
        data.weight ||
        data.vaccinationStatus
      ) {
        mixpanel.track("Used Additional Key Information");
      }

      const { submittedNoteUuid } = await submitBasicNote({
        structuredNoteData,
        props,
        completeNoteSuperSoap,
        mutations,
        template,
        isDraft,
        isAutosave,
        isScribeEdit,
        shouldBillUserForNote,
        inlineEmailExport,
      });

      if (!isAutosave) {
        const uploadedFiles = await submitAttachments({
          getValues,
          setPercentageUploaded,
          setCurrentFileName,
        });

        if (uploadedFiles) {
          await uploadNoteAttachments({
            variables: {
              createdAt: moment().format(),
              noteUuid: submittedNoteUuid,
              attachments: uploadedFiles,
            },
          });
        }
      }

      if (!isSuperSOAP && !isScribeEdit && !isAutosave) {
        navigate(-1); // Go back to the previous page. If we came from the patient notebook, go back to the patient notebook. If we came from the dashboard, go back to the dashboard. If we came from the new note menu, go back to the new note menu. You get the gist.
      }
      if (isScribeEdit && !isAutosave) {
        navigate("/scribe/selectnote", {
          state: { shouldRefetch: true },
        });
      }
    }
  }

  const isEditingCompletedNote = note?.isTagged && note?.isApproved;

  if (note?.isMarkedAsProcessing) {
    return <ProcessingNoteFormFallback note={note} />;
  }
  const isMedicalRecord =
    note?.jobType == "medical_record" ||
    note?.jobType == "appointment";
  return (
    <div>
      {note?.isAutoSoap &&
      !isSuperSOAP &&
      !isScribeEdit &&
      note?.jobType !== "dictation" ? (
        <RecordingTranscriptDrawer note={note} />
      ) : null}

      {note?.isAutoSoap &&
      !isSuperSOAP &&
      !isScribeEdit &&
      isMedicalRecord ? (
        <SOAPExtractionDrawer dataExtraction={note?.dataExtraction} />
      ) : null}

      <FormProvider {...methods}>
        <FindAndReplaceProvider>
          <NoteForm
            note={note}
            control={control}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
            isSuperSOAP={isSuperSOAP}
            isQuicknote={isQuicknote}
            isScribeEdit={isScribeEdit}
            patientUUID={patientUUID}
            noteUUID={noteUUID}
            resetField={resetField}
            shouldShowDateEdit={shouldShowDateEdit}
            setShouldShowDateEdit={setShouldShowDateEdit}
          />
          <FloatingWordMultiEditInput />
        </FindAndReplaceProvider>

        <div className="flex flex-row items-center justify-center">
          {(errors?.dd || errors?.mm || errors?.yyyy) && (
            <ErrorMessage message="Oops! Looks like the note 'Created At' date you entered is invalid." />
          )}
          {errors?.customObjectiveFields && (
            <ErrorMessage message="Oops! Looks like one or more of your custom objective fields doesn't have a name." />
          )}
        </div>

        {shouldShowSubmitButton ? (
          <div className="flex flex-row flex-wrap items-center justify-center mb-10 space-x-4">
            {note && !isSuperSOAP ? (
              <button
                id="trashNote"
                onClick={() => setShouldShowDeleteNoteModal(true)}
                disabled={isFormSubmitted}
                className="border-2 shadow-md border-red-400 rounded-full h-12 w-12 text-lg font-semibold text-white bg-red-400 hover:bg-red-500 disabled:bg-gray-400 disabled:border-gray-400 max-w-xs text-center my-2 transition-all focus:outline-none"
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
            ) : null}
            {note && isSuperSOAP ? (
              <>
                {shouldShowDeleteConfirm ? (
                  <button
                    id="trashNoteSuperSoapConfirm"
                    onClick={() =>
                      deleteNote({
                        variables: { noteUuid: noteUUID },
                      }).then(() => {
                        alert("success", "Note deleted.");
                        setIsForDeletedNote(true);
                        completeNoteSuperSoap(noteUUID);
                      })
                    }
                    disabled={isFormSubmitted}
                    className="border-2 border-red-400 shadow-md rounded-full py-2 px-3 text-lg font-semibold text-white bg-red-400 hover:bg-red-500 disabled:bg-gray-400 disabled:border-gray-400 max-w-xs text-center my-2 transition-all focus:outline-none"
                  >
                    Delete note?
                  </button>
                ) : (
                  <button
                    id="trashNoteSuperSoap"
                    onClick={() => setShouldShowDeleteConfirm(true)}
                    disabled={isFormSubmitted}
                    className="border-2 shadow-md border-red-400 rounded-full h-12 w-12 text-lg font-semibold text-white bg-red-400 hover:bg-red-500 disabled:bg-gray-400 disabled:border-gray-400 max-w-xs text-center my-2 transition-all focus:outline-none"
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                )}
              </>
            ) : null}

            {!isEditingCompletedNote && !isScribeEdit && (
              <button
                id="saveDraftButton"
                disabled={isFormSubmitted}
                onClick={() => {
                  setIsDraftSubmission(true);
                  setIsFormSubmitted(true);
                  if (cancelDebounceOnSubmit) {
                    cancelDebounce();
                  }
                }}
                className="border-2 shadow-md border-indigo-500 disabled:bg-gray-400 disabled:border-gray-400 disabled:text-white disabled:dark:text-white rounded-full px-4 py-2 text-lg font-semibold text-indigo-500 hover:text-white hover:bg-indigo-500  max-w-xs text-center my-2 transition-all focus:outline-none"
              >
                <FontAwesomeIcon icon={faSave} /> Save Draft
              </button>
            )}
            <SaveButton
              type={
                isEditingCompletedNote && !isScribeEdit
                  ? "save"
                  : "submit"
              }
              onClick={() => {
                const latestNote = getValues();
                setIsFormSubmitted(true);
                // if they have the setting enabled, copy the entire note on submit - localstorage for now
                if (
                  localStorage.getItem("should-copy-on-submit") ===
                  "true"
                ) {
                  copyAll({
                    note: latestNote,
                    isForSharedExport: true,
                  });
                }
                if (cancelDebounceOnSubmit) {
                  cancelDebounce();
                }
              }}
              disabled={isFormSubmitted}
              formNoun={"Note"}
              dataCy="submitNoteBtn"
            />
          </div>
        ) : null}
      </FormProvider>
    </div>
  );
}

export default EditableNoteFormWrapper;
