import { gql } from "@apollo/client";

const UPLOAD_SPEED_TEST = gql`
  mutation uploadSpeedTest($file: String!) {
    uploadSpeedTest(file: $file) {
      result {
        success
        fileSize
      }
    }
  }
`;

export default UPLOAD_SPEED_TEST;
