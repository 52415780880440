import { gql, useLazyQuery } from "@apollo/client";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { saveAs } from "file-saver";
import React, { useEffect, useState } from "react";

import NoteCreatedAt from "../notebook/note/NoteCreatedAt";

const GET_AUDIT_TRAIL = gql`
  query auditTrail($uuid: String) {
    auditTrail(uuid: $uuid) {
      uuid
      fileLink
    }
  }
`;

export function AuditTrailListItem({ audit }) {
  const [isDownloadRequested, setIsDownloadRequested] =
    useState(false);
  const [auditTrail, { data }] = useLazyQuery(GET_AUDIT_TRAIL);

  useEffect(() => {
    if (isDownloadRequested) {
      auditTrail({
        variables: {
          uuid: audit.uuid,
        },
      });
    }
  }, [isDownloadRequested]);

  useEffect(() => {
    if (data?.auditTrail?.fileLink && isDownloadRequested) {
      saveAs(data?.auditTrail?.fileLink, audit.fileName);
      setIsDownloadRequested(false);
    }
  }, [data]);

  return (
    <div className="bg-indigo-50 dark:bg-gray-700 rounded-xl p-3 flex flex-row items-center justify-between space-x-4 shadow-sm">
      <NoteCreatedAt createdAt={audit.createdAt} />

      <button
        disabled={isDownloadRequested}
        onClick={() => setIsDownloadRequested(true)}
      >
        <h4 className="text-indigo-500 dark:text-indigo-400 underline">
          <FontAwesomeIcon icon={faFileDownload} /> {audit.fileName}
        </h4>
      </button>
    </div>
  );
}
