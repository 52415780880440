import { faPeopleArrows } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MenuItem, Select } from "@mui/material";
import React from "react";

import { useDarkMode } from "../../../hooks";
import { useTeam } from "../../../hooks/use-team";
import { useTeamMember } from "../../../hooks/use-team-member";

const SelectTeam = ({ className, propHandleChange, rootUser }) => {
  const { currentTeam, setCurrentTeam } = useTeam();
  const { returnToRootUser } = useTeamMember();
  const [enabled] = useDarkMode();

  const handleChange = async (event) => {
    const team = rootUser?.teams.find(
      (team) => team?.name === event.target.value,
    );
    setCurrentTeam(team);
    returnToRootUser();
  };

  const handleSelectChange = propHandleChange || handleChange;

  return (
    <div
      className={`${className} flex flex-row items-center dark:bg-gray-700 bg-gray-100 pl-3 rounded-lg text-indigo-500`}
    >
      <FontAwesomeIcon icon={faPeopleArrows} />
      <Select
        sx={[
          {
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "& .MuiOutlinedInput-input": {
              pl: 0,
              py: 1,
            },
          },

          {
            fontSize: "14px",
            color: enabled ? "rgb(209 213 219)" : "black",
            backgroundColor: enabled
              ? "rgb(55 65 81)"
              : "rgb(243 244 246)",
            borderRadius: "8px",
            flex: 1,
            width: 160,
          },
        ]}
        label="Current Team"
        labelId="helper-label"
        value={currentTeam?.name || rootUser?.teams[0]?.name}
        onChange={handleSelectChange}
        MenuProps={{
          PaperProps: {
            style: {
              color: enabled ? "#f9fafb" : "black",
              backgroundColor: enabled
                ? "rgb(55 65 81)"
                : "rgb(243 244 246)",
            },
          },
        }}
      >
        {rootUser?.teams.map((team) => (
          <MenuItem
            key={team.name}
            value={team.name}
            sx={{
              fontSize: "14px",
            }}
          >
            <h4 className="text-sm ml-2 font-medium truncate">
              {team.name}
            </h4>
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default SelectTeam;
