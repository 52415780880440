import { useMutation } from "@apollo/client";
import axios from "axios";

import medicalRecordAudioFromFrontend from "../assets/other/medical_record.wav";
import { SCRIBENOTE_FLASK } from "../constants.js";
import UPLOAD_SPEED_TEST from "../graphql/mutations/UploadSpeedTest.js";

const MEDICAL_RECORD_AUDIO_FROM_SERVER =
  SCRIBENOTE_FLASK + "/static/medical_record.wav";
export function useNetworkTests() {
  const [uploadSpeedTest] = useMutation(UPLOAD_SPEED_TEST);

  const fetchStaticFileAsBlob = async (fileUrl) => {
    try {
      const response = await axios.get(fileUrl, {
        responseType: "blob",
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching static file:", error);
      throw error;
    }
  };

  const testPing = async () => {
    const testUrl = "https://placehold.co/1x1";
    const startTime = performance.now();
    await axios.get(testUrl, { method: "HEAD" });
    const endTime = performance.now();

    return parseFloat((endTime - startTime).toFixed(2));
  };

  const testDownloadSpeed = async () => {
    const fileUrl = MEDICAL_RECORD_AUDIO_FROM_SERVER;
    const startTime = performance.now();

    // Fetch the file as a blob
    const response = await axios.get(fileUrl, {
      responseType: "blob",
    });

    const endTime = performance.now();

    // Blob size in bytes
    const fileSizeInBytes = response.data.size;

    // Elapsed time in seconds
    const elapsedTimeInSeconds = (endTime - startTime) / 1000;

    // Calculate download speed in Mbps
    const downloadSpeedMbps =
      (fileSizeInBytes * 8) / (elapsedTimeInSeconds * 1000000);

    return parseFloat(downloadSpeedMbps.toFixed(2));
  };

  const testUploadSpeed = async (file) => {
    const convertFileToBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result.split(",")[1]);
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });

    const base64File = await convertFileToBase64(file);

    // Measure baseline latency (without uploading any data)
    const latencyStart = performance.now();
    await uploadSpeedTest({ variables: { file: "" } }); // Send an empty file to measure latency
    const latencyEnd = performance.now();
    const baselineLatencyInSeconds =
      (latencyEnd - latencyStart) / 1000;

    // Start the timer for the actual upload
    const startTime = performance.now();

    const { data } = await uploadSpeedTest({
      variables: { file: base64File },
    });

    const endTime = performance.now();

    const elapsedTimeInSeconds = (endTime - startTime) / 1000;

    // Ensure elapsed time minus baseline latency is not negative
    const uploadTimeInSeconds = Math.max(
      elapsedTimeInSeconds - baselineLatencyInSeconds,
      0.001, // Prevent division by zero or negative values
    );

    const fileSizeInBytes = data.uploadSpeedTest.result.fileSize;
    const uploadSpeedMbps =
      (fileSizeInBytes * 8) / (uploadTimeInSeconds * 1000000);

    return parseFloat(uploadSpeedMbps.toFixed(2));
  };

  const runNetworkTests = async () => {
    const file = await fetchStaticFileAsBlob(
      medicalRecordAudioFromFrontend,
    );
    const [ping, download, upload] = await Promise.all([
      testPing(),
      testDownloadSpeed(),
      file ? testUploadSpeed(file) : null,
    ]);

    // Leave in logs for the user to see
    console.log("Ping (ms):", ping);
    console.log("Download Speed (Mbps):", download);
    console.log("Upload Speed (Mbps):", upload);

    return {
      ping, // Latency in ms
      download, // Download speed in Mbps
      upload, // Upload speed in Mbps
    };
  };

  return {
    testPing,
    testDownloadSpeed,
    testUploadSpeed,
    runNetworkTests,
  };
}
