import {
  pdf,
  Page,
  Text,
  View,
  Font,
  Document,
  Image,
} from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import mixpanel from "mixpanel-browser";
import moment from "moment";
import React from "react";

import { styles } from "./PDFStyles.js";
import { getObjectiveSectionText } from "./utils.js";
import InterBold from "../../../assets/fonts/Inter-Bold.ttf";
import InterMedium from "../../../assets/fonts/Inter-Medium.ttf";
import InterRegular from "../../../assets/fonts/Inter-Regular.ttf";
import LogoImage from "../../../assets/imgs/scribenote-2024-png.png";
import {
  BAR,
  NORMAL_ON_EXAM,
  OBJECTIVE_FIELDS,
} from "../../../constants.js";
import { useLocalStorage } from "../../../hooks";
import { getName } from "../../export/utils";
import {
  filterSummaryData,
  getAllKeyInfo,
} from "../../notebook/summary/utils";
import DentalChart from "../../notes/edit/form/DentalChart";
import { getPatientIntactText } from "../../patients/utils";
import { alert } from "../Alert";

Font.register({
  family: "Inter-Bold",
  format: "truetype",
  fonts: [
    {
      src: InterBold,
      fontWeight: 700,
    },
  ],
});

Font.register({
  family: "Inter-Medium",
  format: "truetype",
  fonts: [
    {
      src: InterMedium,
      fontWeight: 400,
    },
  ],
});

Font.register({
  family: "Inter",
  format: "truetype",
  fonts: [
    {
      src: InterRegular,
    },
  ],
});

function getNoteDate(note) {
  return moment(note.createdAt).format("MM-DD-YYYY");
}

function getNoteTime(note, isForDisplay) {
  let noteTime = "";
  if (isForDisplay) {
    noteTime = moment(note.createdAt).format("h:mm a");
  } else {
    noteTime = moment(note.createdAt).format("h mm a");
  }
  return noteTime;
}

function SoapSectionPDF({ soapType, soapText }) {
  var capitalizedTitle = "";
  if (soapType === "additional") {
    capitalizedTitle = "Additional Notes";
  } else {
    capitalizedTitle =
      soapType.charAt(0).toUpperCase() + soapType.slice(1);
  }

  return (
    <View
      style={!styles[soapType] ? styles.quicknote : styles[soapType]}
    >
      <Text style={styles.heading}>{capitalizedTitle}</Text>
      {soapText && soapText?.length > 0 ? (
        <Text>{soapText}</Text>
      ) : (
        <Text style={styles.placeholder}>No {soapType} data.</Text>
      )}
    </View>
  );
}

function ObjectiveFieldsPDF({
  OBJECTIVE_FIELDS_TO_SHOW,
  note,
  getObjectiveFieldStyling,
  thisFieldType,
}) {
  return OBJECTIVE_FIELDS_TO_SHOW.map(
    ({ name, label, type }, index) => {
      if (type === thisFieldType) {
        return (
          <Text
            key={index}
            style={getObjectiveFieldStyling(note[name])}
          >
            <Text style={styles.objItemTitle}>{label}: </Text>
            {note[name]}
          </Text>
        );
      }
    },
  );
}

export function TranscriptSection({ transcript }) {
  return (
    <View style={styles.transcript}>
      <Text style={styles.heading}>Transcript</Text>
      <Text style={styles.textSmall}>{transcript}</Text>
    </View>
  );
}

function CustomObjectiveFieldsPDF({
  note,
  getObjectiveFieldStyling,
}) {
  if (!note?.customObjectiveFields?.length > 0) {
    return null;
  }

  return (
    <View>
      <View style={styles.separatorContainer}>
        <View style={styles.separatorLine}></View>
        <Text style={styles.textbold}>Custom</Text>
      </View>
      {note?.customObjectiveFields.map(
        (
          {
            name,
            value,
            symmetryType,
            symmetricalValueLeft,
            symmetricalValueRight,
            symmetryLabels,
          },
          index,
        ) => {
          if (symmetryType === "None") {
            return (
              <Text
                key={index}
                style={getObjectiveFieldStyling(note[name])}
              >
                <Text style={styles.objItemTitle}>{name}: </Text>
                {value}
              </Text>
            );
          } else {
            return (
              <View
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Text
                  style={{
                    width: 40,
                    color: "#F472B6",
                    fontFamily: "Inter-Medium",
                  }}
                >
                  {name}:{" "}
                </Text>
                <View style={{ flex: 1 }}>
                  <Text
                    style={getObjectiveFieldStyling(
                      symmetricalValueLeft,
                    )}
                  >
                    <Text
                      style={{
                        fontFamily: "Inter-Medium",
                      }}
                    >
                      {symmetryLabels.left}:
                    </Text>{" "}
                    {symmetricalValueLeft}{" "}
                  </Text>
                </View>

                <View style={{ flex: 1 }}>
                  <Text
                    style={getObjectiveFieldStyling(
                      symmetricalValueRight,
                    )}
                  >
                    <Text
                      style={{
                        fontFamily: "Inter-Medium",
                      }}
                    >
                      {symmetryLabels.right}:
                    </Text>{" "}
                    {symmetricalValueRight}
                  </Text>
                </View>
              </View>
            );
          }
        },
      )}
    </View>
  );
}

function ClinicLogoAndInfo({ user }) {
  return (
    <>
      <View>
        {user?.account?.logoFileLink ? (
          <Image
            style={{
              width: "80px",
            }}
            src={user?.account?.logoFileLink}
          />
        ) : (
          <Image
            style={{
              width: "100px",
              marginTop: 10,
              marginRight: 5,
            }}
            src={LogoImage}
          />
        )}
      </View>
      {user?.account?.headerInfoPdf ? (
        <View style={styles.headerBox}>
          <Text style={styles.headingSmall}>Clinic Info</Text>
          <Text style={styles.textExtraSmall}>
            {user?.account?.headerInfoPdf}
          </Text>
        </View>
      ) : null}
    </>
  );
}

function PatientDetails({ patient }) {
  return (
    <View style={styles.headerBoxL}>
      <Text style={styles.headingSmall}>Patient Signalment</Text>
      <Text style={styles.textExtraSmall}>
        <Text style={styles.textExtraSmallBold}>Species: </Text>
        {patient?.species}
      </Text>
      <Text style={styles.textExtraSmall}>
        <Text style={styles.textExtraSmallBold}>Breed: </Text>
        {patient?.breed}
      </Text>
      <Text style={styles.textExtraSmall}>
        <Text style={styles.textExtraSmallBold}>Sex: </Text>
        {patient?.sex},{" "}
        {getPatientIntactText({
          sex: patient?.sex,
          intact: patient?.isIntact,
        })}
      </Text>
      <Text style={styles.textExtraSmall}>
        <Text style={styles.textExtraSmallBold}>Weight: </Text>
        {patient?.weight}
      </Text>
      <Text style={styles.textExtraSmall}>
        <Text style={styles.textExtraSmallBold}>Age: </Text>
        {patient?.age}
      </Text>
      {patient?.color ? (
        <Text style={styles.textExtraSmall}>
          <Text style={styles.textExtraSmallBold}>Color: </Text>
          {patient?.color}
        </Text>
      ) : null}
      {patient?.microchip ? (
        <Text style={styles.textExtraSmall}>
          <Text style={styles.textExtraSmallBold}>Microchip #: </Text>
          {patient?.microchip}
        </Text>
      ) : null}
    </View>
  );
}

function PatientContactInfo({ patient }) {
  return (
    <View style={styles.headerBoxR}>
      <Text style={styles.headingSmall}>Patient Contact</Text>

      {patient?.ownerContactInfo ? (
        <Text style={styles.textExtraSmall}>
          <Text style={styles.textExtraSmallBold}>
            Owner Contact:{" "}
          </Text>
          {patient?.ownerContactInfo}
        </Text>
      ) : null}
      {patient?.emergencyContactInfo ? (
        <Text style={styles.textExtraSmall}>
          <Text style={styles.textExtraSmallBold}>
            Emergency Contact:{" "}
          </Text>
          {patient?.emergencyContactInfo}
        </Text>
      ) : null}
    </View>
  );
}

function SoapSectionOfPDF({ note }) {
  const [shouldShowNoteKeyInfo] = useLocalStorage(
    "should-export-note-key-info",
  );
  const {
    subjective,
    assessment,
    plan,
    additionalNotes,
    customFormFields,
    shouldUseCustomForm,
    customText,
  } = note;

  const getObjectiveFieldStyling = (prop) => {
    if (
      prop !== NORMAL_ON_EXAM &&
      prop !== BAR &&
      prop !== "" &&
      prop !== null
    ) {
      return styles.objHighlight;
    } else {
      return styles.objNormal;
    }
  };

  let OBJECTIVE_FIELDS_TO_SHOW = [];
  // this will filter out the objective fields that are not supposed to be shown
  OBJECTIVE_FIELDS.filter((field) => {
    if (note[field.name] !== null) {
      OBJECTIVE_FIELDS_TO_SHOW.push(field);
    }
  });

  function renderObjectiveFieldsTitle(type, title) {
    if (
      OBJECTIVE_FIELDS_TO_SHOW.filter((item) => item.type === type)
        .length > 0
    ) {
      return (
        <View style={styles.separatorContainer}>
          <View style={styles.separatorLine}></View>
          <Text style={styles.textbold}>{title}</Text>
        </View>
      );
    } else {
      return null;
    }
  }

  return (
    <>
      {shouldUseCustomForm ? (
        <View
          style={{
            paddingHorizontal: 5,
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "space-between",
            marginTop: 10,
          }}
        >
          <View
            style={
              note?.jobType === "dental_record"
                ? { width: 400, marginRight: 40 }
                : null
            }
          >
            <Text style={styles.textSmall}>
              {customFormFields
                ? customFormFields[0]?.textValue
                : customText}
            </Text>
          </View>
          {note?.jobType === "dental_record" ? (
            <View style={{ width: 300 }}>
              <DentalChart
                record={
                  customFormFields
                    ? customFormFields[0]?.textValue
                    : customText
                }
                isForPDF={true}
              />
            </View>
          ) : null}
        </View>
      ) : (
        <>
          <SoapSectionPDF
            soapType={"subjective"}
            soapText={subjective}
          />

          {note?.shouldUseGenericObjective ? (
            <SoapSectionPDF
              soapType={"objective"}
              soapText={getObjectiveSectionText(note)}
            />
          ) : (
            <View style={styles.objective}>
              <Text style={styles.heading}>Objective</Text>

              {renderObjectiveFieldsTitle("vital", "Vitals")}
              {note?.weight && note?.weight !== NORMAL_ON_EXAM ? (
                <Text>
                  <Text style={styles.objItemTitle}>Weight: </Text>
                  <Text>{note?.weight}</Text>
                </Text>
              ) : null}

              <ObjectiveFieldsPDF
                OBJECTIVE_FIELDS_TO_SHOW={OBJECTIVE_FIELDS_TO_SHOW}
                note={note}
                getObjectiveFieldStyling={getObjectiveFieldStyling}
                thisFieldType={"vital"}
              />

              {renderObjectiveFieldsTitle("system", "Systems")}
              <ObjectiveFieldsPDF
                OBJECTIVE_FIELDS_TO_SHOW={OBJECTIVE_FIELDS_TO_SHOW}
                note={note}
                getObjectiveFieldStyling={getObjectiveFieldStyling}
                thisFieldType={"system"}
              />

              {renderObjectiveFieldsTitle("overall", "Overall")}
              <ObjectiveFieldsPDF
                OBJECTIVE_FIELDS_TO_SHOW={OBJECTIVE_FIELDS_TO_SHOW}
                note={note}
                getObjectiveFieldStyling={getObjectiveFieldStyling}
                thisFieldType={"overall"}
              />

              <CustomObjectiveFieldsPDF
                note={note}
                getObjectiveFieldStyling={getObjectiveFieldStyling}
              />
            </View>
          )}

          <SoapSectionPDF
            soapType={"assessment"}
            soapText={assessment}
          />
          <SoapSectionPDF soapType={"plan"} soapText={plan} />

          <SoapSectionPDF
            soapType={"additional"}
            soapText={additionalNotes}
          />
        </>
      )}

      {shouldShowNoteKeyInfo ? (
        <View
          style={{
            border: "1 solid #E5E7EB",
            borderRadius: 5,
            marginRight: 5,
            marginLeft: 5,
            padding: 10,
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
            marginTop: 10,
          }}
        >
          <Text style={[styles.headingSmall, { marginBottom: 2 }]}>
            Additional Key Information:
          </Text>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              paddingVertical: 2,
            }}
          >
            <Text style={[styles.textSmall, { color: "gray" }]}>
              Key History:{" "}
            </Text>
            <Text style={[styles.textSmall, { maxWidth: "400px" }]}>
              {note?.keyHistory || "None recorded."}
            </Text>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              paddingVertical: 2,
            }}
          >
            <Text style={[styles.textSmall, { color: "gray" }]}>
              Key Medications:{" "}
            </Text>
            <Text style={[styles.textSmall, { maxWidth: "300px" }]}>
              {note?.keyMedications || "None recorded."}
            </Text>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              paddingVertical: 2,
            }}
          >
            <Text style={[styles.textSmall, { color: "gray" }]}>
              Vaccination Status:{" "}
            </Text>
            <Text style={[styles.textSmall, { maxWidth: "300px" }]}>
              {note?.vaccinationStatus || "None recorded."}
            </Text>
          </View>
        </View>
      ) : null}
    </>
  );
}

function ByScribenoteText({ user }) {
  if (user?.account?.logoFileLink) {
    return (
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "start",
        }}
      >
        {/* <Text style={styles.textSmall}>by</Text> */}
        <Image
          style={{
            width: "60px",
          }}
          src={LogoImage}
        />
      </View>
    );
  } else {
    return null;
  }
}

export function NoteWrapper({
  note,
  patient,
  user = null,
  isBulkExport = false,
  children,
}) {
  const { title } = note;

  const [shouldShowPatientSignalment] = useLocalStorage(
    "should-show-patient-signalment",
  );

  const firstName = note?.user?.firstName || user?.firstName;
  const lastName = note?.user?.lastName || user?.lastName;
  const fullName = firstName + " " + lastName;
  return (
    <Document>
      <Page
        size="LETTER"
        style={{
          margin: "30px",
          paddingBottom: "100px",
          paddingTop: "20px",
        }}
      >
        <View style={styles.topRight}>
          <ByScribenoteText user={user} />
        </View>
        <View style={styles.header}>
          <ClinicLogoAndInfo user={user} />

          <View style={styles.datetime}>
            <Text style={styles.headingSmall}>Note Details</Text>
            <Text style={styles.textExtraSmall}>
              Created at: {getNoteDate(note)},{" "}
              {getNoteTime(note, true)}
              {firstName && lastName && (
                <>
                  {"\n"}Created by: {fullName}
                </>
              )}
            </Text>
          </View>
        </View>

        <View style={styles.view}>
          <View style={styles.separatorContainer}>
            <Text style={styles.patient}>{getName(patient)}</Text>
            {patient ? (
              <Text style={styles.text}> (Patient)</Text>
            ) : null}
            <View
              style={[
                styles.separatorLineBold,
                { marginVertical: 5 },
              ]}
            ></View>
          </View>

          {shouldShowPatientSignalment && patient && !isBulkExport ? (
            <View style={styles.header}>
              <PatientDetails patient={patient} />
              {patient?.ownerContactInfo ||
              patient?.emergencyContactInfo ? (
                <PatientContactInfo patient={patient} />
              ) : null}
            </View>
          ) : null}
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              maxWidth: "90vw",
              paddingLeft: 5,
              paddingBottom: 10,
            }}
          >
            {title ? (
              <Text style={styles.textbold}>{title}</Text>
            ) : null}
          </View>
          {children}
        </View>
      </Page>
    </Document>
  );
}

// Create Document Component
export function NotePDF({
  note,
  patient,
  user = null,
  isBulkExport = false,
}) {
  return (
    <NoteWrapper
      note={note}
      patient={patient}
      user={user}
      isBulkExport={isBulkExport}
    >
      <SoapSectionOfPDF note={note} />
    </NoteWrapper>
  );
}

export function ClientSummaryPDF({
  frontmatter,
  backmatter,
  clientSummary,
  user,
  note,
}) {
  const date = moment().format("MM-DD-YYYY");
  return (
    <Document>
      <Page
        size="LETTER"
        style={{
          margin: "30px",
          paddingBottom: "100px",
          paddingTop: "20px",
        }}
      >
        <View style={styles.topRight}>
          <ByScribenoteText user={user} />
        </View>
        <View style={styles.header}>
          <ClinicLogoAndInfo user={user} />

          <View style={styles.datetime}>
            <Text style={styles.headingSmall}>Note Details</Text>
            <Text style={styles.textExtraSmall}>
              Created at: {date}
              {"\n"}
              {user?.isDoctor && "Dr. "}
              {note?.firstName || user?.firstName}{" "}
              {note?.lastName || user?.lastName}
            </Text>
          </View>
        </View>

        <View style={styles.view}>
          <View style={styles.separatorContainer}>
            <Text style={styles.patient}>Appointment Summary</Text>

            <View
              style={[
                styles.separatorLineBold,
                { marginVertical: 5 },
              ]}
            ></View>
          </View>
          <Text style={styles.text}>
            This is important information for you to know about your
            pet's recent appointment with {user?.isDoctor && "Dr. "}
            {note?.firstName || user?.firstName}{" "}
            {note?.lastName || user?.lastName}.
          </Text>
          <View style={styles.quicknote}>
            <Text>{frontmatter}</Text>
            <Text>{clientSummary}</Text>
            <Text>{backmatter}</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
}

function PatientPDF({ patient, user }) {
  const { allKeyHistories, allKeyMedications, allKeyVaccinations } =
    getAllKeyInfo({ patient });
  const [shouldEnablePageSaver] = useLocalStorage("page-saver");

  function renderNotesList() {
    if (shouldEnablePageSaver) {
      return (
        <Page
          size="LETTER"
          style={{
            margin: "30px",
            paddingBottom: "100px",
            paddingTop: "20px",
          }}
        >
          {patient?.notes
            ?.filter((note) => note?.isTagged)
            .map((note) => (
              <View key={note.uuid} style={styles.view}>
                <View style={styles.separatorContainer}>
                  {note?.title ? (
                    <Text style={styles.headingSmall}>
                      {note?.title}{" "}
                      <Text style={styles.textSmall}>
                        (
                        {moment(note.createdAt).format("MMM DD YYYY")}
                        )
                      </Text>
                    </Text>
                  ) : (
                    <Text style={styles.headingSmall}>
                      Note from{" "}
                      {moment(note.createdAt).format("MMM DD YYYY")}
                    </Text>
                  )}
                  <View
                    style={[
                      styles.separatorLineBold,
                      { marginVertical: 5 },
                    ]}
                  ></View>
                </View>

                <SoapSectionOfPDF note={note} />
              </View>
            ))}
        </Page>
      );
    } else {
      return (
        <>
          {patient?.notes?.map((note) => {
            if (note?.isTagged) {
              return (
                <Page
                  key={note.uuid}
                  size="LETTER"
                  style={{
                    margin: "30px",
                    paddingBottom: "100px",
                    paddingTop: "20px",
                  }}
                >
                  <View style={styles.view}>
                    <View style={styles.separatorContainer}>
                      {note?.title ? (
                        <Text style={styles.headingSmall}>
                          {note?.title}{" "}
                          <Text style={styles.textSmall}>
                            (
                            {moment(note.createdAt).format(
                              "MMM DD YYYY",
                            )}
                            )
                          </Text>
                        </Text>
                      ) : (
                        <Text style={styles.headingSmall}>
                          Note from{" "}
                          {moment(note.createdAt).format(
                            "MMM DD YYYY",
                          )}
                        </Text>
                      )}
                      <View
                        style={[
                          styles.separatorLineBold,
                          { marginVertical: 5 },
                        ]}
                      ></View>
                    </View>

                    <SoapSectionOfPDF note={note} />
                  </View>
                </Page>
              );
            }
          })}
        </>
      );
    }
  }

  function renderKeyInfoList(allKeyInformation, keyInfoType) {
    const filteredKeyInformation =
      filterSummaryData(allKeyInformation);

    if (filteredKeyInformation && filteredKeyInformation.length > 0) {
      return (
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            paddingVertical: 1,
          }}
        >
          <Text
            style={[
              styles.textSmall,
              {
                backgroundColor: "lightgray",
                borderRadius: 2,
                padding: 1,
                marginBottom: 2,
              },
            ]}
          >
            {patient[keyInfoType]
              ? patient[keyInfoType]
              : "None recorded."}
          </Text>

          {allKeyInformation
            .filter((keyInfo) => keyInfo.text !== null)
            .map((keyInfo) => {
              return (
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  key={keyInfo.noteUuid}
                >
                  <Text
                    style={[
                      styles.textExtraSmall,
                      { marginRight: 2, maxWidth: "125px" },
                    ]}
                  >
                    {keyInfo.text}
                  </Text>
                  <Text
                    style={[
                      styles.textExtraSmall,
                      {
                        color: "gray",
                      },
                    ]}
                  >
                    {getNoteDate(keyInfo)}
                  </Text>
                </View>
              );
            })}
        </View>
      );
    } else {
      return (
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            paddingVertical: 1,
            minHeight: "30px",
          }}
        >
          <Text
            style={[
              styles.textSmall,
              {
                backgroundColor: "lightgray",
                borderRadius: 2,
                padding: 1,
                marginBottom: 2,
              },
            ]}
          >
            {patient[keyInfoType]
              ? patient[keyInfoType]
              : "None recorded."}
          </Text>
        </View>
      );
    }
  }

  return (
    <Document>
      <Page
        size="LETTER"
        style={{
          margin: "30px",
          paddingBottom: "100px",
          paddingTop: "20px",
        }}
      >
        <View style={styles.topRight}>
          <Text style={styles.text}>Patient Records</Text>

          <ByScribenoteText user={user} />
        </View>
        <View style={styles.header}>
          <ClinicLogoAndInfo user={user} />

          <View style={styles.datetime}>
            <Text style={styles.headingSmall}>Note Details</Text>
            <Text style={styles.textExtraSmall}>
              Exported at: {moment().format("MMM DD YYYY")},{" "}
              {moment().format("hh:mm A")}
            </Text>
          </View>
        </View>

        <View style={styles.view}>
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "90vw",
            }}
          >
            <Text style={styles.patient}>
              Records for {getName(patient)}
            </Text>
            <View style={styles.headerPatientInfo}>
              <PatientDetails patient={patient} />
              {patient?.ownerContactInfo ||
              patient?.emergencyContactInfo ? (
                <PatientContactInfo patient={patient} />
              ) : null}
            </View>
            <View
              style={{
                marginTop: 10,
                display: "flex",
                flexDirection: "row",
                border: "1 solid #E5E7EB",
                borderRadius: 5,
              }}
            >
              <View
                style={{
                  padding: 5,
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                }}
              >
                <Text
                  style={[
                    styles.headingSmall,
                    { paddingVertical: 2, textAlign: "center" },
                  ]}
                >
                  Key Medical History:{" "}
                </Text>
                {renderKeyInfoList(allKeyHistories, "keyHistory")}
              </View>
              <View
                style={{
                  borderLeft: "1 solid #E5E7EB",
                  borderRight: "1 solid #E5E7EB",
                  padding: 5,
                  marginHorizontal: 5,
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                }}
              >
                <Text
                  style={[
                    styles.headingSmall,
                    { paddingVertical: 2, textAlign: "center" },
                  ]}
                >
                  Chronic Medications:{" "}
                </Text>
                {renderKeyInfoList(
                  allKeyMedications,
                  "keyMedications",
                )}
              </View>

              <View
                style={{
                  padding: 5,
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                }}
              >
                <Text
                  style={[
                    styles.headingSmall,
                    { paddingVertical: 2, textAlign: "center" },
                  ]}
                >
                  Immunization Record:{" "}
                </Text>
                {renderKeyInfoList(
                  allKeyVaccinations,
                  "vaccinationStatus",
                )}
              </View>
            </View>
          </View>
        </View>
      </Page>
      {renderNotesList()}
    </Document>
  );
}

export async function getPDFBlob(note, patient, user = null) {
  const doc = (
    <NotePDF
      note={note}
      patient={patient}
      user={user}
      isBulkExport={true}
    />
  );
  const asPdf = pdf([]); // [] is important, throws without an argument
  asPdf.updateContainer(doc);
  const blob = await asPdf.toBlob();

  return blob;
}

export async function checkisAllowedAndDownload(
  note,
  patient,
  user = null,
) {
  mixpanel.track("Download Note PDF/Print");
  const doc = <NotePDF note={note} patient={patient} user={user} />;
  const asPdf = pdf([]); // [] is important, throws without an argument
  asPdf.updateContainer(doc);
  const blob = await asPdf.toBlob();

  saveAs(
    blob,
    `Scribenote-${getName(patient)}-${getNoteDate(
      note,
    )}-${getNoteTime(note, false)}.pdf`,
  );
}

export async function downloadClientSummary(
  frontmatter,
  backmatter,
  clientSummary,
  user = null,
  note,
) {
  mixpanel.track("Download Client Summary PDF/Print");
  const doc = (
    <ClientSummaryPDF
      frontmatter={frontmatter}
      backmatter={backmatter}
      clientSummary={clientSummary}
      user={user}
      note={note}
    />
  );
  const asPdf = pdf([]);
  asPdf.updateContainer(doc);
  const blob = await asPdf.toBlob();
  const currentDate = new Date();
  const timestamp = currentDate.getTime();
  const fileName = `Scribenote-Client-Summary-${timestamp}.pdf`;
  saveAs(blob, fileName);
}

export async function checkisAllowedAndDownloadEntirePatient(
  user,
  patient,
  setIsExporting,
) {
  alert(
    "info",
    "Entire notebook exports might take a few minutes. Please hold tight while we wrangle up this notebook's records.",
  );
  setIsExporting(true);
  if (patient && user) {
    const doc = <PatientPDF patient={patient} user={user} />;
    const asPdf = pdf([]); // [] is important, throws and error without an argument
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(
      blob,
      `Scribenote-Patient-Notebook-for-${getName(
        patient,
      )}-${moment().format("DD-MM-YYYY - hh:mm:ss")}.pdf`,
    );

    setIsExporting(false);
  } else {
    setIsExporting(false);
    return null;
  }
}
