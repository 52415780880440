import {
  faChevronDown,
  faChevronUp,
  faExternalLinkAlt,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";
import mixpanel from "mixpanel-browser";
import React, { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useSpring, animated, config } from "react-spring";

import TeamUserDropdown from "./TeamUserDropdown";
import { usePreventRecordingInterruptions } from "../../../hooks";
import { useAuth } from "../../../hooks";
import { useTeam } from "../../../hooks/use-team";
import { RandomUserImage } from "../../common/Randomizer.js";

function MenuItem({ onClick, title, icon }) {
  return (
    <div
      className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-100 dark:hover:bg-gray-800/50 hover:bg-gray-100 hover:text-gray-900 dark:hover:text-gray-100 cursor-pointer"
      role="menuitem"
      onClick={onClick}
    >
      {icon ? <FontAwesomeIcon icon={icon} /> : null} {title}
    </div>
  );
}

const AuthNav = ({ setIsModalShowing, show }) => {
  const [shouldShowMenu, setShouldShowMenu] = useState(false);
  const auth = useAuth();
  const { currentTeam } = useTeam();
  const { adminPage } = useFlags();
  const { handleActionWithPrevention } =
    usePreventRecordingInterruptions();

  const menuAnim = useSpring({
    config: config.wobbly,
    opacity: shouldShowMenu ? 1 : 0,
    transform: shouldShowMenu ? "scale(1)" : "scale(0.8)",
  });

  /**
   * Hook that alerts clicks outside of the passed ref
   */
  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShouldShowMenu(false);
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  const navigate = useNavigate();

  const menuClassName = shouldShowMenu
    ? "origin-top-right absolute right-0 mt-3 w-48 rounded-md shadow-lg bg-white dark:bg-gray-700 divide-y divide-gray-100 dark:divide-gray-600 z-50"
    : "hidden";

  const menuItemOnClick = (path) => {
    handleActionWithPrevention(() => {
      setShouldShowMenu(false);
      navigate(path);
    });
  };

  // if user authenticated, then we can show their username in the navbar.
  if (auth?.isUserAuthenticated) {
    return (
      <div
        className="relative inline-block text-left md:pl-3"
        ref={wrapperRef}
      >
        <div className="flex flex-row">
          <TeamUserDropdown
            onLabelClick={() => setIsModalShowing(true)}
          />
          <div className="flex items-center">
            <button
              className="focus:outline-none"
              type="button"
              id="options-menu"
              onClick={() => setShouldShowMenu(!shouldShowMenu)}
            >
              <div className="group flex flex-row items-center hover:bg-gray-50 dark:hover:bg-gray-700/50 py-1 px-2 rounded-lg transition-all">
                <h4 className="dark:text-white group-hover:text-indigo-500 transition-all flex flex-row items-center space-x-2">
                  <FontAwesomeIcon
                    icon={
                      shouldShowMenu ? faChevronUp : faChevronDown
                    }
                    size="xs"
                    className="text-gray-400 dark:text-gray-500"
                  />
                  <span
                    className="hidden md:block"
                    data-cy="navUserName"
                  >
                    {auth?.userFirstName}
                  </span>
                </h4>
                {currentTeam ? (
                  <Avatar
                    sx={{
                      width: 36,
                      height: 36,
                      fontSize: 14,
                      ml: 1,
                    }}
                  >
                    {auth?.userFirstName?.charAt(0) ?? "?"}
                  </Avatar>
                ) : (
                  <div className="inline-flex justify-center rounded-full shadow-sm ml-2 h-10 w-10 bg-gray-200 focus:outline-none">
                    <RandomUserImage />
                  </div>
                )}
              </div>
            </button>
          </div>
        </div>

        <animated.div
          style={menuAnim}
          className={menuClassName}
          role="menu"
        >
          <div className="py-1">
            {auth?.orgUuid && adminPage && (
              <MenuItem
                onClick={() => menuItemOnClick("/admin")}
                title="Admin Management"
              />
            )}
            <MenuItem
              onClick={() => menuItemOnClick("/account")}
              title="Account"
            />
            <MenuItem
              onClick={() => menuItemOnClick("/dashboard")}
              title="Dashboard"
            />

            <MenuItem
              onClick={() => menuItemOnClick("/inbox")}
              title="Inbox"
            />
            <MenuItem
              onClick={() => menuItemOnClick("/notebook")}
              title="Patient Notebook"
            />
          </div>
          <div className="py-1">
            <MenuItem
              onClick={() => menuItemOnClick("/new_note/select")}
              title="New Typed Note"
            />
            <MenuItem
              onClick={() => menuItemOnClick("/templates")}
              title="Typed Note Templates"
            />
          </div>
          <div className="py-1">
            <div
              className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-100 dark:hover:bg-gray-800 hover:bg-gray-100 hover:text-gray-900 dark:hover:text-gray-100 cursor-pointer"
              role="menuitem"
              onClick={() => {
                setShouldShowMenu(false);
                const newWindow = window.open(
                  "https://docs.scribenote.com/",
                  "_blank",
                  "noopener,noreferrer",
                );
                if (newWindow) {
                  newWindow.opener = null;
                }
              }}
            >
              Help Docs{" "}
              <FontAwesomeIcon icon={faExternalLinkAlt} size="xs" />
            </div>
            <div
              className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-100 dark:hover:bg-gray-800 hover:bg-gray-100 hover:text-gray-900 dark:hover:text-gray-100 cursor-pointer"
              role="menuitem"
              onClick={() => {
                show(); // show the intercom messenger
              }}
            >
              Support{" "}
              <FontAwesomeIcon icon={faExternalLinkAlt} size="xs" />
            </div>
          </div>
          <div className="py-1">
            <MenuItem
              onClick={async () => {
                setShouldShowMenu(false);
                await auth.logout();
                mixpanel.track("Logged Out");
              }}
              title="Log out"
              icon={faSignOutAlt}
            />
          </div>
        </animated.div>
      </div>
    );
  } else {
    return null;
  }
};

export default AuthNav;
