import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import mixpanel from "mixpanel-browser";
import React from "react";

import { getCouponInfo } from "../api/Billing.js";
import { ErrorMessage } from "../components/common/inputs/Input.js";

export function bytesToMB(bytes) {
  const bytesPerMB = 1024 * 1024;
  const megabytes = bytes / bytesPerMB;
  return megabytes;
}

export async function validateCoupon(
  value,
  setIsPromoCodeValid,
  setPromoCode,
  setPromoCodeErrorMessage,
  planType = "unlimited", // default to unlimited to allow for promo code validation on the sign-up page
) {
  if (!value) {
    setIsPromoCodeValid(null);
    setPromoCode(null);
    return;
  }

  const couponInfo = await getCouponInfo(value);
  const referral = couponInfo?.referral;

  if (couponInfo?.coupon) {
    const allowedProducts = couponInfo?.allowedProducts || [];
    const promoCode = couponInfo?.promoCode;
    const isPromoCodeActive = promoCode["data"][0]["active"];

    if (isPromoCodeActive) {
      if (allowedProducts.includes(planType)) {
        setIsPromoCodeValid(true);
        setPromoCode(couponInfo);
        mixpanel.track("Promo Code Applied", {
          promoCode: value,
        });
      } else {
        setIsPromoCodeValid(false);
        setPromoCodeErrorMessage(
          `Sorry, this coupon does not apply to the ${planType} plan.`,
        );
        mixpanel.track("Promo Code Not Applicable", {
          promoCode: value,
        });
        setPromoCode(null);
      }
    } else {
      setIsPromoCodeValid(false);
      setPromoCodeErrorMessage(
        `Sorry, this coupon code is inactive.`,
      );
      setPromoCode(null);
    }
  } else if (referral) {
    setIsPromoCodeValid(true);
    setPromoCode(referral);
    mixpanel.track("Referral Code Applied", {
      promoCode: value,
    });
  } else {
    setIsPromoCodeValid(false);
    setPromoCodeErrorMessage(
      "Sorry, this coupon is invalid. Please try again or contact Scribenote support.",
    );
    setPromoCode(null);
  }
}

export function renderPromoCodeMessage(
  promoCode,
  isPromoCodeValid,
  promoCodeErrorMessage,
) {
  if (isPromoCodeValid === true && promoCode) {
    return (
      <h5 className="text-xs mt-1 mb-2">
        <FontAwesomeIcon
          icon={faCheckCircle}
          className="text-green-500 mr-1"
        />
        {promoCode?.referrer ? "Invite" : "Promo"} code applied
        successfully!
      </h5>
    );
  } else if (isPromoCodeValid === null) {
    return null;
  } else {
    return <ErrorMessage message={promoCodeErrorMessage} />;
  }
}

export function renderPromoCodeDetails(promoCode, isPromoCodeValid) {
  if (isPromoCodeValid === true && promoCode) {
    if (promoCode?.referrer) {
      return (
        <div>
          <ul className="text-xs text-gray-600 dark:text-gray-400 pl-4">
            <li>
              Send {promoCode?.referrer} a thank you from the
              Scribenote team 💜
            </li>
            <li>You've been awarded {promoCode?.bonus}</li>
          </ul>
        </div>
      );
    } else {
      return (
        <div>
          <ul className="text-xs text-gray-600 dark:text-gray-400 pl-4">
            <li>
              Discount:{" "}
              {promoCode?.coupon?.percent_off
                ? `${promoCode.coupon.percent_off}% off`
                : `$${(promoCode.coupon.amount_off / 100).toFixed(
                    2,
                  )} ${promoCode.coupon.currency.toUpperCase()}`}
            </li>
            <li>
              Duration:{" "}
              {promoCode?.coupon?.duration_in_months
                ? `${promoCode?.coupon?.duration_in_months} months`
                : promoCode?.coupon?.duration === "forever"
                ? "on every invoice"
                : promoCode?.coupon?.duration}
            </li>
            {promoCode?.promoCode?.data[0]?.expires_at ? (
              <li>
                Expires on{" "}
                {new Date(
                  promoCode?.promoCode?.data[0]?.expires_at * 1000, // Convert Unix timestamp to milliseconds
                ).toLocaleDateString(undefined, {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                  hour12: true,
                })}
              </li>
            ) : null}
          </ul>
        </div>
      );
    }
  } else {
    return null;
  }
}
