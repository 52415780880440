import React, { useEffect, useState } from "react";

export default function PricingCard({
  price,
  children,
  shouldShowYearlyPricing,
  planName,
  onClick,
  isForCheckout,
  promoCode,
}) {
  const [calculatedPrice, setCalculatedPrice] = useState(price);

  useEffect(() => {
    function calculatePrice() {
      let coupon = promoCode?.coupon;
      if (coupon) {
        const discountPercent = coupon.percent_off
          ? (100 - coupon.percent_off) / 100
          : 1 - coupon.amount_off / 100 / price;
        const discountedPrice = Math.floor(price * discountPercent);
        return discountedPrice;
      } else {
        return price;
      }
    }

    const newPrice = calculatePrice();

    setCalculatedPrice(newPrice);
  }, [promoCode, price]);

  const term = shouldShowYearlyPricing ? "year" : "month";

  let bgClasses =
    "bg-gradient-to-br dark:from-indigo-800/50 dark:via-indigo-600/50 dark:to-indigo-800/50 from-indigo-50 via-indigo-200 to-indigo-50";
  let titleClasses =
    "text-transparent dark:text-transparent bg-clip-text bg-gradient-to-r from-indigo-400 to-indigo-600 dark:from-indigo-500 dark:to-indigo-300";

  return (
    <div
      onClick={onClick}
      className={`w-72 md:w-80 rounded-xl shadow-md p-6 flex flex-col items-center justify-between transition-all cursor-pointer ${bgClasses} ${
        isForCheckout
          ? "scale-90"
          : "scale-100 hover:scale-105 hover:shadow-lg"
      }`}
    >
      <h2 className={`mt-4 font-extrabold text-4xl ${titleClasses}`}>
        {planName}
      </h2>
      <div>{children}</div>
      <div className="bg-indigo-500/10 px-2 py-4 rounded-xl w-full text-center">
        <h4 className="text-lg md:text-3xl text-indigo-500 font-bold">
          <span>${calculatedPrice}</span>
          <span className="text-base"> USD</span>
        </h4>

        <h5 className="text-sm text-indigo-400">
          per DVM per {term}
        </h5>
      </div>
    </div>
  );
}
