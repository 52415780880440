import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FormControl,
  ListSubheader,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import React from "react";

import { useDarkMode } from "../../../hooks";

export default function RecordingJobTypeSelection({
  selectedJobType,
  setSelectedJobType,
}) {
  const [enabled] = useDarkMode();

  const handleChange = (event) => {
    const newJobType = event.target.value;
    setSelectedJobType(newJobType);
  };

  const MenuProps = {
    PaperProps: {
      sx: {
        bgcolor: enabled ? "rgb(31 41 55)" : "white", // Dropdown background
        "& .MuiMenuItem-root": {
          fontSize: "14px",
          color: enabled ? "#f9fafb" : "black", // Text color
          padding: "11px",
          borderBottom: enabled
            ? "0.1px solid rgba(255,255,255, 0.1)"
            : "0.2px solid rgba(0, 0, 0, 0.12)", // Border
        },
        "& .MuiList-root": {
          padding: 0,
        },
        "& .MuiListSubheader-root": {
          fontSize: "14px",
          color: enabled ? "#f9fafb" : "black", // Subheader text color
          fontWeight: "bold",
          padding: "0px 8px",
          backgroundColor: enabled
            ? "rgb(55 65 81)"
            : "rgb(243 244 246)", // Subheader background color
          borderBottom: "2px solid rgba(0, 0, 0, 0.12)", // Subheader border\
          lineHeight: "50px",
        },
        "& .MuiMenuItem-root:hover": {
          bgcolor: enabled
            ? "rgb(75 85 99)"
            : "rgba(229, 231, 235, 1)", // Hover background color
        },
        "& .Mui-selected": {
          bgcolor: enabled
            ? "rgb(75 85 99)"
            : "rgba(229, 231, 235, 1)", // Selected background color
        },
      },
    },
  };

  return (
    <div className="flex flex-col items-center">
      <FormControl sx={{ width: 210 }} size="small">
        <Select
          sx={[
            {
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            },
            {
              fontSize: "14px",
              color: enabled ? "#f9fafb" : "black",
              backgroundColor: enabled
                ? "rgb(55 65 81)"
                : "rgb(243 244 246)",
              borderRadius: "8px",
              paddingTop: 0,
            },
          ]}
          MenuProps={MenuProps}
          id="demo-select-small"
          value={selectedJobType}
          onChange={handleChange}
        >
          <ListSubheader>SOAP Notes</ListSubheader>
          <MenuItem
            sx={{ fontSize: "14px" }}
            value={"medical_record"}
          >
            <Tooltip
              title={
                "Select 'Medical Record' to generate an automatic SOAP note for your patient."
              }
              arrow
              placement="right"
            >
              <div>
                Medical Record
                <FontAwesomeIcon
                  className="ml-2"
                  icon={faInfoCircle}
                />
              </div>
            </Tooltip>
          </MenuItem>
          <MenuItem sx={{ fontSize: "14px" }} value={"multipet"}>
            <Tooltip
              title={
                "Select 'Multipet' to generate a SOAP note for 2-3 patients"
              }
              arrow
              placement="right"
            >
              <div>
                <span className="bg-yellow-400 rounded-md p-1 text-xs font-semibold mr-1">
                  Beta
                </span>
                Multipet
                <FontAwesomeIcon
                  className="ml-2"
                  icon={faInfoCircle}
                />
              </div>
            </Tooltip>
          </MenuItem>
          <MenuItem sx={{ fontSize: "14px" }} value={"packmode"}>
            <Tooltip
              title={
                "Select 'Pack Mode' to generate a SOAP note for 4 or more patients"
              }
              arrow
              placement="right"
            >
              <div>
                <span className="bg-yellow-400 rounded-md p-1 text-xs font-semibold mr-1">
                  Beta
                </span>
                Pack Mode
                <FontAwesomeIcon
                  className="ml-2"
                  icon={faInfoCircle}
                />
              </div>
            </Tooltip>
          </MenuItem>
          <ListSubheader>Other</ListSubheader>
          <MenuItem sx={{ fontSize: "14px" }} value={"recap"}>
            <Tooltip
              title={
                "Select 'Recap' to summarize meetings or any other type of recording."
              }
              arrow
              placement="right"
            >
              <div>
                Recap
                <FontAwesomeIcon
                  className="ml-2"
                  icon={faInfoCircle}
                />
              </div>
            </Tooltip>
          </MenuItem>
          <MenuItem sx={{ fontSize: "14px" }} value={"callback"}>
            <Tooltip
              title={
                "Select 'Callback' to generate a short summary of your call with a client."
              }
              arrow
              placement="right"
            >
              <div>
                Callback
                <FontAwesomeIcon
                  className="ml-2"
                  icon={faInfoCircle}
                />
              </div>
            </Tooltip>
          </MenuItem>
          <MenuItem sx={{ fontSize: "14px" }} value={"dental_record"}>
            <Tooltip
              title={
                "Select 'Dental Record' to generate a dental record based on a tooth-by-tooth dictation, including a visual dental chart."
              }
              arrow
              placement="right"
            >
              <div>
                Dental Record
                <FontAwesomeIcon
                  className="ml-2"
                  icon={faInfoCircle}
                />
              </div>
            </Tooltip>
          </MenuItem>
          <MenuItem
            sx={{ fontSize: "14px" }}
            value={"abdominal_ultrasound"}
          >
            <Tooltip
              title={
                "Select 'Abdominal Ultrasound' to generate a systematic review of organ findings during or after completing a scan"
              }
              arrow
              placement="right"
            >
              <div>
                <span className="bg-yellow-400 rounded-md p-1 text-xs font-semibold mr-1">
                  Beta
                </span>
                Abd. Ultrasound
                <FontAwesomeIcon
                  className="ml-2"
                  icon={faInfoCircle}
                />
              </div>
            </Tooltip>
          </MenuItem>
          <MenuItem sx={{ fontSize: "14px" }} value={"dictation"}>
            <Tooltip
              title={
                "Select 'Dictation' to generate a transcript of your recording."
              }
              arrow
              placement="right"
            >
              <div>
                Dictation
                <FontAwesomeIcon
                  className="ml-2"
                  icon={faInfoCircle}
                />
              </div>
            </Tooltip>
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}
