import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";

export function MultiSelectActionButton({
  onClick,
  onClickActionDescription,
  icon,
  buttonColor,
  disabled = false,
}) {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={`${
        buttonColor
          ? buttonColor
          : "bg-indigo-500 hover:bg-indigo-600"
      } p-3 disabled:bg-gray-400 disabled:cursor-not-allowed cursor-pointer rounded-full text-white text-sm shadow-md transition-all`}
    >
      <FontAwesomeIcon icon={icon} /> {onClickActionDescription}
    </button>
  );
}

MultiSelectActionButton.propTypes = {
  numSelectedItems: PropTypes.number,
  selectedItemsName: PropTypes.string,
  onClick: PropTypes.func,
};
