import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  datetime: {
    display: "flex",
    flexGrow: "1",
    height: "100%",
    border: "1 solid #E5E7EB",
    borderRadius: 5,
    marginRight: 5,
    marginLeft: 5,
    padding: "5px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
  },

  headerBox: {
    display: "flex",
    flex: 1,
    height: "100%",
    border: "1 solid #E5E7EB",
    borderRadius: 5,
    marginRight: 5,
    marginLeft: 5,
    padding: "5px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
  },

  headerBoxL: {
    display: "flex",
    flex: 1,
    height: "100%",
    border: "1 solid #E5E7EB",
    borderRadius: 5,
    padding: "5px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
    marginRight: 5,
  },

  headerBoxR: {
    display: "flex",
    flex: 1,
    height: "100%",
    border: "1 solid #E5E7EB",
    borderRadius: 5,
    padding: "5px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
    marginLeft: 5,
  },

  topRight: {
    position: "absolute",
    display: "flex",
    top: 0,
    right: 65,
  },

  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    maxWidth: "90vw",
    maxHeight: "100px",
    marginTop: "10px",
  },

  headerPatientInfo: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    maxWidth: "90vw",
    marginTop: "10px",
  },

  quicknote: {
    display: "flex",
    maxWidth: "90vw",
    borderRadius: 5,
    marginVertical: 10,
    padding: 10,
    fontSize: "8pt",
    fontFamily: "Inter",
    fontWeight: "normal",
    backgroundColor: "#FAFAFA",
  },

  transcript: {
    display: "flex",
    maxWidth: "90vw",
    borderRadius: 5,
    border: "1 solid #cccccc",
    marginVertical: 5,
    padding: 10,
    fontSize: "8pt",
    fontFamily: "Inter",
    fontWeight: "normal",
  },

  subjective: {
    display: "flex",
    maxWidth: "90vw",
    borderRadius: 5,
    border: "1 solid #60A5FA",
    marginVertical: 5,
    padding: 10,
    fontSize: "8pt",
    fontFamily: "Inter",
    fontWeight: "normal",
    backgroundColor: "#EFF6FF",
  },

  objective: {
    display: "flex",
    maxWidth: "90vw",
    borderRadius: 5,
    border: "1 solid #F472B6",
    marginVertical: 5,
    padding: 10,
    fontSize: "8pt",
    fontFamily: "Inter",
    fontWeight: "normal",
    backgroundColor: "#FDF2F8",
  },

  assessment: {
    display: "flex",
    maxWidth: "90vw",
    borderRadius: 5,
    border: "1 solid #34D399",
    marginVertical: 5,
    padding: 10,
    fontSize: "8pt",
    fontFamily: "Inter",
    fontWeight: "normal",
    backgroundColor: "#ECFDF5",
  },

  plan: {
    display: "flex",
    maxWidth: "90vw",
    borderRadius: 5,
    border: "1 solid #FBBF24",
    marginVertical: 5,
    padding: 10,
    fontSize: "8pt",
    fontFamily: "Inter",
    fontWeight: "normal",
    backgroundColor: "#FFFBEB",
  },

  view: {
    display: "flex",
    flexFlow: "column wrap",
    maxWidth: "90vw",
    marginTop: "10px",
  },

  separatorContainer: {
    display: "flex",
    maxWidth: "90vw",
    flexDirection: "row",
    marginVertical: "5px",
    alignItems: "center",
  },

  separatorLine: {
    flex: 1,
    border: "0.5 solid #F472B6",
    borderRadius: 100,
    marginRight: "5px",
  },

  separatorLineBold: {
    flex: 1,
    border: "1 solid #000000",
    borderRadius: 100,
    marginLeft: "5px",
  },

  separatorLineSimple: {
    flex: 1,
    border: "0.5 solid #A1A1AA",
    borderRadius: 100,
    marginRight: "5px",
  },

  heading: {
    fontSize: "12pt",
    fontFamily: "Inter-Bold",
    fontWeight: "700",
    fontStyle: "normal",
    display: "inline",
    paddingBottom: "10px",
  },

  headingSmall: {
    fontSize: "10pt",
    fontFamily: "Inter-Bold",
    fontWeight: "700",
    fontStyle: "normal",
    display: "inline",
    paddingBottom: "2px",
  },

  text: {
    fontSize: "10pt",
    fontFamily: "Inter",
    fontWeight: "400",
    fontStyle: "normal",
    display: "inline",
  },

  textSmall: {
    fontSize: "8pt",
    fontFamily: "Inter",
    fontWeight: "400",
    fontStyle: "normal",
    display: "inline",
  },

  textExtraSmall: {
    fontSize: "7pt",
    fontFamily: "Inter",
    fontWeight: "400",
    fontStyle: "normal",
    display: "inline",
  },

  textExtraSmallBold: {
    fontSize: "7pt",
    fontFamily: "Inter-Bold",
    fontWeight: "400",
    fontStyle: "normal",
    display: "inline",
  },

  textbold: {
    fontSize: "10pt",
    fontFamily: "Inter-Medium",
    fontWeight: "400",
    fontStyle: "normal",
    display: "inline",
  },

  placeholder: {
    fontSize: "8pt",
    fontFamily: "Inter",
    fontWeight: "400",
    fontStyle: "normal",
    color: "gray",
  },

  patient: {
    fontSize: "18pt",
    fontFamily: "Inter-Bold",
    fontWeight: "700",
    marginRight: 5,
  },

  objHighlight: {
    backgroundColor: "#FBCFE8",
    marginBottom: 1,
  },

  objHighlightSimple: {
    backgroundColor: "#ADE0FF",
    marginBottom: 1,
  },

  objNormal: {
    marginBottom: 1,
  },

  objItemTitleSimple: {
    color: "#000000",
    fontFamily: "Inter-Medium",
  },

  objItemTitle: {
    color: "#F472B6",
    fontFamily: "Inter-Medium",
  },
});
