import { gql, useQuery } from "@apollo/client";
import React from "react";
import { useParams } from "react-router";

import { AuditTrailList } from "./AuditTrailList";
import FullScreenLoading from "../common/loading/FullScreenLoading";
import FourohFour from "../home/FourohFour";
import PageTitle from "../layout/PageTitle";

const GET_NOTE_AUDIT_TRAIL = gql`
  query noteAuditTrail($uuid: String) {
    note(uuid: $uuid) {
      auditTrail {
        uuid
        fileName
        createdAt
      }
    }
  }
`;

export function AuditTrailNote() {
  const { noteUUID } = useParams();

  const { data, loading } = useQuery(GET_NOTE_AUDIT_TRAIL, {
    variables: { uuid: noteUUID },
  });

  if (!data && loading) {
    <FullScreenLoading loadingTrigger={loading} />;
  }

  if (!data) {
    return <FourohFour />;
  }

  return (
    <div>
      <PageTitle className="mb-6" title="Past note revisions" />
      <AuditTrailList auditTrail={data?.note?.auditTrail} />
    </div>
  );
}
