import {
  faChevronDown,
  faChevronUp,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import mixpanel from "mixpanel-browser";
import React, { useEffect, useState } from "react";
import { useSpring, animated, config } from "react-spring";

import CopyIndividualField from "./CopyIndividualField";
import { alert } from "../../../common/Alert";
import HeadingWrapper from "../../../common/HeadingWrapper";

export function SOAPExtractionDrawer({ dataExtraction }) {
  const [shouldShow, setShouldShow] = useState(false);
  const [canParse, setCanParse] = useState(false);

  useEffect(() => {
    // We don't want to have a scenario where the page can't load
    // because we're expecting valid json breaks and this component breaks
    try {
      JSON.parse(dataExtraction);
      setCanParse(true);
    } catch (e) {
      setCanParse(false);
    }
  }, [dataExtraction]);

  async function copy(textToCopy) {
    navigator.clipboard.writeText(textToCopy).then(() => {
      alert("info", `Copied text: "${textToCopy}"`);
    });
  }

  const slideAnim = useSpring({
    transform: shouldShow ? "translateY(20px)" : "translateY(425px)",
    config: config.default,
  });

  const capitalizeFirstLetter = (string) => {
    if (string === undefined) {
      return "";
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  const capitalizeFirstLetterOfEachWord = (string) => {
    return string
      .split(" ")
      .map((word) => word?.charAt(0)?.toUpperCase() + word?.slice(1))
      .join(" ");
  };

  const labelKeys = (key) => {
    if (key === undefined) {
      return "";
    }

    switch (key) {
      case "urination_defecation":
        return "Urination/Defecation";
      case "reason_for_appointment":
        return "Reason for Appointment";
      case "oral_dental":
        return "Oral/Dental";
      case "overall_health_status":
        return "Overall Health";
      case "masses_and_wounds":
        return "Masses/Wounds";
      case "fur_skin":
        return "Fur/Skin";
      case "nutritional_and_gastrointestinal_health":
        return "Nutritional/Gastrointestinal Health";
      case "dental_and_oral_health":
        return "Dental/Oral Health";
      case "sensory_and_ocular_health":
        return "Sensory/Ocular Health";
      case "reproductive_and_sexual_health":
        return "Reproductive Health";
      case "physical_condition_and_mobility":
        return "Physical Condition/Mobility";
      case "stable_for_vaccines":
        return "Stable for Vaccines";
      case "recommendations_for_owner":
        return "Recommendations for Owner";
      case "tests_treatments_completed_during_appointment":
        return "Tests/Treatments Completed";
      case "follow_up_plans":
        return "Follow-Up Plans";
      case "information_needed_from_vet":
        return "Information Needed from Vet";
      default:
        return capitalizeFirstLetterOfEachWord(
          key?.replace(/_/g, " "),
        );
    }
  };

  const NestedObject = ({ object }) => {
    // Function to check if a string is a digit
    const isDigit = (str) => /^\d+$/.test(str);

    // Function to determine if the value is renderable as a string
    const isRenderable = (value) => {
      return typeof value !== "object" || value === null;
    };

    return (
      <div className="relative ml-4 mb-2">
        {Object.entries(object)?.map(([key, value], index) => {
          // Check if the key is a digit, if so, do not display the key
          const displayKey = isDigit(key) ? (
            ""
          ) : (
            <>
              <div className="absolute -left-2 h-5 w-[1px] bg-indigo-200 dark:bg-gray-500"></div>

              <span className="text-xs md:text-sm font-semibold">
                {labelKeys(key)}:
              </span>
            </>
          );

          return (
            <div key={key + index} className="data-row">
              {isRenderable(value) ? (
                value === "" || value === null ? null : (
                  <div
                    onClick={() =>
                      copy(
                        `${labelKeys(key)}: ${capitalizeFirstLetter(
                          value,
                        )}`,
                      )
                    }
                    className="select-all data-row"
                  >
                    {displayKey}
                    <span className="text-sm ml-1">
                      {capitalizeFirstLetter(value?.toString())}
                    </span>
                  </div>
                )
              ) : (
                <>
                  {displayKey}
                  {value && <NestedObject object={value} />}
                  {/* how do we make sure this doesn't break when the value is null */}
                </>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  const renderData = (item) => {
    if (
      Object.prototype.hasOwnProperty.call(item, "id") &&
      Object.prototype.hasOwnProperty.call(item, "function")
    ) {
      // accommodate for OLD JSON FORMAT
      item = item?.function?.arguments;
    }
    if (!item) {
      return null;
    }
    let data = null;
    try {
      data = JSON.parse(item);
    } catch (e) {
      mixpanel.track("Error Parsing JSON for data extraction", {
        error: e,
        parsedData: item,
      });
      return null;
    }
    if (!data) {
      return null;
    }

    const key = Object.keys(data)[0];

    if (!key || !data[key] || typeof data[key] !== "object") {
      return null;
    }

    return (
      <div key={key} className="flex flex-col pb-6">
        <HeadingWrapper
          heading={
            <>
              <h4 className="font-bold">
                {capitalizeFirstLetterOfEachWord(key)}
              </h4>
              <CopyIndividualField
                onClick={() => copySOAPSection(key)}
                isPositionRelative={true}
              />
            </>
          }
        />
        {Object.entries(data[key])?.map(
          ([subKey, value], subIndex) => {
            if (typeof value === "object" && value !== null) {
              if (Object.keys(value)?.length === 0) {
                return null;
              }
              //iterate through they key value pairs in the arr
              return (
                <h5
                  key={subKey + subIndex}
                  className="data-row leading-3"
                >
                  <span className="text-xs md:text-sm font-semibold mr-1">
                    {labelKeys(subKey)}:
                  </span>
                  <NestedObject object={value} />
                </h5>
              );
            } else if (
              value !== null &&
              value != "" &&
              value !== "unknown"
            ) {
              return (
                <div
                  key={subKey + subIndex}
                  className="data-row select-all"
                  onClick={() =>
                    copy(
                      `${labelKeys(subKey)}: ${capitalizeFirstLetter(
                        value?.toString(),
                      )}`,
                    )
                  }
                >
                  <h5 className="text-xs md:text-sm">
                    <span className="font-semibold mr-1">
                      {labelKeys(subKey)}:
                    </span>
                    {capitalizeFirstLetter(value?.toString())}
                  </h5>
                </div>
              );
            }
          },
        )}
      </div>
    );
  };

  if (!dataExtraction || !canParse) {
    return null;
  }

  const formatDataForCopying = (jsonData) => {
    let formattedText = "";

    Object.keys(jsonData).forEach((section) => {
      let details = "";
      try {
        details = JSON.parse(jsonData[section]);
      } catch (e) {
        console.error(e);
        alert("error", "Could not copy data.");
        return null;
      }

      Object.entries(details).forEach(([key, value]) => {
        //  Add section title with formatting (Subjective, Objective, etc.)
        formattedText += `**${
          key.charAt(0).toUpperCase() + key.slice(1)
        }:**\n`;

        Object.entries(value).forEach(([subKey, subValue]) => {
          // Use the labelKeys function for custom labels (e.g. urination_defecation -> Urination/Defecation)
          const formattedKey = labelKeys(subKey);

          // check if subValue is an object, if so, drill down into it
          if (typeof subValue === "object" && subValue !== null) {
            // Drill down into object/array values (Eg. Medications, Vaccines, etc.)
            if (Object.entries(subValue).length > 0) {
              formattedText += `${formattedKey}: \n`;
            }
            Object.entries(subValue).forEach(([, subSubValue]) => {
              formattedText += `   - `;
              // Drill down further into object/array values (Eg. Medication name, dosage, etc.)
              Object.entries(subSubValue).forEach(
                ([subSubSubKey, subSubSubValue]) => {
                  // Check for non-empty subSubValues
                  if (
                    subSubSubValue !== null &&
                    subSubSubValue !== ""
                  ) {
                    formattedText += `${labelKeys(
                      subSubSubKey,
                    )}: ${subSubSubValue}. `;
                  }
                },
              );
              formattedText += `  \n`;
            });
          } else {
            if (subValue !== null && subValue !== "") {
              // Check for non-empty subValues that are not objects or arrays (Eg. Patient Name, Age, etc.)
              formattedText += `${formattedKey}: ${
                typeof subValue === "string" // make sure if it's not a string, we don't capitalize it - otherwise it will break
                  ? capitalizeFirstLetter(subValue)
                  : subValue
              }\n`;
            }
          }
        });
      });

      formattedText += "\n"; // Add a newline for spacing between sections
    });

    return formattedText;
  };

  function copyText() {
    const textToCopy = formatDataForCopying(
      JSON.parse(dataExtraction),
    );

    navigator.clipboard.writeText(textToCopy).then(() => {
      alert("info", "Copied all structured data!");
    });
  }

  function copySOAPSection(key) {
    const textToCopy = formatDataForCopying(
      JSON.parse(dataExtraction),
    );

    // Convert the key to start with an uppercase letter, followed by lowercase, to match section titles
    const sectionTitle =
      key.charAt(0).toUpperCase() + key.slice(1).toLowerCase();

    // Create a regular expression to match the section based on the title
    // This regex looks for the title, followed by any characters until the next title or end of text
    const regex = new RegExp(
      `\\*\\*${sectionTitle}:\\*\\*(.*?)\\n(?=\\*\\*|$)`,
      "gs",
    );

    // Search for matches
    const match = regex.exec(textToCopy);

    // Return the matched section, or an empty string if no match was found
    const sectionToCopy = match ? match[1].trim() : "";

    navigator.clipboard.writeText(sectionToCopy).then(() => {
      alert("info", `Copied ${key} section!`);
    });
  }

  return (
    <animated.div
      style={slideAnim}
      className="fixed bottom-0 right-28 z-30 w-full flex-col items-center justify-center pointer-events-none"
    >
      <div className="flex flex-col items-center pointer-events-auto">
        <div
          className="relative flex flex-col items-center bg-white dark:bg-gray-700 pt-6 pb-4 px-6 rounded-t-2xl"
          style={{
            boxShadow: "0px -5px 50px -10px rgba(0,0,0,0.40)",
          }}
        >
          <button
            onClick={() => {
              setShouldShow(!shouldShow);
              mixpanel.track("Clicked Show Extracted Data Button");
            }}
            className="absolute -top-10 z-40 bg-indigo-400 dark:bg-indigo-500 py-2 px-8 rounded-t-2xl flex flex-row items-center"
          >
            <h4 className="text-white">
              {shouldShow ? "Hide Data" : "Show Data"}
              <FontAwesomeIcon
                icon={shouldShow ? faChevronDown : faChevronUp}
                className="ml-2"
              />
            </h4>
          </button>
          <div
            className="text-xs md:text-sm w-[300px] md:w-[600px] h-96 overflow-y-auto"
            onClick={() =>
              mixpanel.track("Clicked/Copied Extracted Data")
            }
          >
            <div className="absolute top-2 right-2">
              <CopyIndividualField onClick={() => copyText()} />
            </div>
            <h5
              className={`text-xs md:text-sm text-gray-400 dark:text-gray-500 text-center`}
            >
              <FontAwesomeIcon icon={faInfoCircle} /> Click on any
              individual line of data to automatically copy it to your
              clipboard,
              <br />
              or use the copy buttons to copy sections or the entirety
              of the data.
            </h5>
            {JSON.parse(dataExtraction)?.map(renderData)}
          </div>
        </div>
      </div>
    </animated.div>
  );
}
