import {
  faChevronDown,
  faChevronUp,
  faCopy,
  faFilePdf,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import moment from "moment";
import React, { useState } from "react";
import { config, animated, useSpring } from "react-spring";

import { alert } from "../../../common/Alert";
import {
  NoteWrapper,
  TranscriptSection,
} from "../../../common/clinical/NotePDF";
import { getName } from "../../../export/utils";

export function RecordingTranscriptDrawer({ note }) {
  const [shouldShow, setShouldShow] = useState(false);
  const slideAnim = useSpring({
    transform: shouldShow ? "translateY(0%)" : "translateY(100%)",
    config: config.default,
  });
  const transcript = note?.transcription || "";

  const isMedicalRecord =
    (note?.jobType == "medical_record" ||
      note?.jobType == "appointment") &&
    note?.dataExtraction;

  const copyTranscript = () => {
    navigator.clipboard.writeText(transcript).then(() => {
      alert("info", "Copied!");
    });
  };

  const downloadTranscriptAsPDF = async () => {
    try {
      const doc = (
        <NoteWrapper
          note={note}
          patient={note?.patient}
          user={note?.user}
        >
          <TranscriptSection transcript={transcript} />
        </NoteWrapper>
      );
      const asPdf = pdf([]); // [] is important, throws an error without an argument
      asPdf.updateContainer(doc);
      const blob = await asPdf.toBlob();
      saveAs(
        blob,
        `Scribenote-Patient-Notebook-Transcript-for-${getName(
          note?.patient,
        )}-${moment().format("DD-MM-YYYY - hh:mm:ss")}.pdf`,
      );
    } catch (e) {
      alert("error", "Failed to download PDF transcript");
    }
  };

  const parseTranscript = (transcript) => {
    //Split on <>{timestamp information}<>
    const parts = transcript.split(/<>([^<>]+)<>(?!.*<>)/g);

    return parts.map((part, index) => {
      //If the index is odd, it's a timestamp
      //This % logic is important in case there are multiple timestamps
      if (index % 2 === 1) {
        return (
          <strong key={index} className="mt-2 block italic">
            {part.trim()}
          </strong>
        );
      }
      //Otherwise, it's just regular transcript content
      return (
        <span className="mt-2 block" key={index}>
          {part.trim()}
        </span>
      );
    });
  };

  return (
    <animated.div
      style={slideAnim}
      className={`fixed bottom-0 ${
        isMedicalRecord ? "left-28" : "left-0"
      } z-30 w-full flex flex-col items-center justify-center pointer-events-none ${
        shouldShow ? "z-40" : null
      }`}
    >
      <div className="flex flex-col items-center pointer-events-auto">
        <div
          className="relative flex flex-col items-center bg-white dark:bg-gray-700 pt-6 pb-4 px-6 rounded-t-2xl"
          style={{
            boxShadow: "0px -5px 50px -10px rgba(0,0,0,0.40)",
          }}
        >
          <button
            onClick={() => setShouldShow(!shouldShow)}
            className="absolute -top-10 z-40 bg-indigo-400 dark:bg-indigo-500 py-2 px-8 rounded-t-2xl"
          >
            <h4 className="text-white">
              {shouldShow ? "Hide Transcript" : "Show Transcript"}{" "}
              <FontAwesomeIcon
                icon={shouldShow ? faChevronDown : faChevronUp}
              />
            </h4>
          </button>
          <h5 className="text-xs md:text-sm w-[300px] md:w-[600px] max-h-[50vh] overflow-y-auto whitespace-break-spaces">
            {parseTranscript(transcript)}
          </h5>
          <div className="pt-4 flex gap-4">
            <button
              title="Copy Transcript"
              className={`self-center border rounded-full w-8 h-8 bg-indigo-500 border-indigo-500 hover:bg-indigo-700 text-white transition-all font-medium outline-none text-sm shadow-md focus:outline-none`}
              onClick={copyTranscript}
            >
              <FontAwesomeIcon icon={faCopy} />
            </button>
            <button
              title="Download Transcript As PDF"
              className={`self-center border rounded-full w-8 h-8 bg-indigo-500 border-indigo-500 hover:bg-indigo-700 text-white transition-all font-medium outline-none text-sm shadow-md focus:outline-none`}
              onClick={downloadTranscriptAsPDF}
            >
              <FontAwesomeIcon icon={faFilePdf} />
            </button>
          </div>
        </div>
      </div>
    </animated.div>
  );
}
