import axios from "axios";
import mixpanel from "mixpanel-browser";

import signS3 from "./SignS3.js";
import { bytesToMB } from "./utils.js";
export async function uploadToCloud({
  blob,
  createNewNote,
  handleUploadProgress = null,
  jobType,
  syncedRecordingUUID = undefined,
}) {
  const formData = new FormData();
  const signS3Response = await signS3();
  const { data, storageObjectName } = signS3Response.data;
  const audioStorageLink = data.url;
  const { fields } = data;

  for (const key in fields) {
    formData.append(key, fields[key]);
  }
  formData.append("file", blob);

  const cancelTokenSource = axios.CancelToken.source();
  let uploadStartTime = Date.now();

  const uploadTimeout = setTimeout(async () => {
    const elapsedTime = (Date.now() - uploadStartTime) / 1000;
    cancelTokenSource.cancel("Request timed out after 10 seconds");
    mixpanel.track("Upload Progress Timeout", {
      error: "Request timed out after 10 seconds",
      fileSizeMB: bytesToMB(blob.size).toFixed(2),
      elapsedTime,
      audioStorageLink,
      syncedRecordingUUID,
    });
  }, 10000);

  const syncUploadTimeout = setTimeout(async () => {
    const elapsedTime = (Date.now() - uploadStartTime) / 1000;
    cancelTokenSource.cancel("Request timed out after 120 seconds");
    mixpanel.track("Sync Upload Timeout", {
      error: "Request timed out after 120 seconds",
      fileSizeMB: bytesToMB(blob.size).toFixed(2),
      elapsedTime,
      audioStorageLink,
      syncedRecordingUUID,
    });
  }, 120000);

  mixpanel.track("Upload Started", {
    fileSizeMB: bytesToMB(blob.size).toFixed(2),
    syncedRecordingUUID,
  });

  try {
    await axios.post(data.url, formData, {
      headers: { "Access-Control-Allow-Origin": true },
      transformRequest: (formData) => formData,
      cancelToken: cancelTokenSource.token,
      onUploadProgress: (progressEvent) => {
        clearTimeout(uploadTimeout);
        if (handleUploadProgress) {
          handleUploadProgress(progressEvent);
        }
      },
    });

    const newNoteData = await createNewNote(
      audioStorageLink,
      storageObjectName,
      jobType,
    );

    const elapsedTime = (Date.now() - uploadStartTime) / 1000;
    mixpanel.track("Upload Completed", {
      elapsedTime,
      syncedRecordingUUID,
    });

    return { success: true, note: newNoteData };
  } catch (error) {
    if (axios.isCancel(error)) {
      mixpanel.track("Upload Canceled", {
        fileSizeMB: bytesToMB(blob.size).toFixed(2),
        error: error.message || error,
        storageObjectName,
        audioStorageLink,
        syncedRecordingUUID,
      });
    } else {
      mixpanel.track("Upload Failed", {
        fileSizeMB: bytesToMB(blob.size).toFixed(2),
        error: error.message || error,
        storageObjectName,
        audioStorageLink,
        syncedRecordingUUID,
      });
    }
    throw error; // Re-throw for parent function to catch
  } finally {
    clearTimeout(uploadTimeout);
    clearTimeout(syncUploadTimeout);
  }
}

export async function submitPreRecordedSampleAudio({
  createNewNote,
}) {
  /**
   * Used for submitting onboarding audio file if the user chooses to use pre-recorded.
   *
   * Why are we doing this?
   * Cause the user needs a NEW note so that they can edit it and see it in their dashboard after the process.
   * However, we need to make that new note with an audio file. So saving the file in assets, then getting the
   *   blob, then uploading the file with the same function as regular recordings used seems to keep things clean
   * while allowing a new note to be created every time.
   *
   */

  const audioUrl = require("../assets/other/onboarding-appt-example.wav");

  try {
    const response = await fetch(audioUrl);
    const blob = await response.blob();
    const newNoteData = await uploadToCloud({
      blob,
      createNewNote,
    });
    return newNoteData;
  } catch (error) {
    alert(
      "error",
      "An error occurred. Please try again later or contact Scribenote Support.",
    );
    throw error;
  }
}

export async function submitTestingSampleAudio({
  createNewNote,
  audioUrl,
}) {
  /**
   * Used for submitting testing audio files if the tester chooses to use pre-record
   *
   * Why are we doing this?
   * Cause the user needs a NEW note so that they can edit it and see it in their dashboard after the process.
   * However, we need to make that new note with an audio file. So saving the file in assets, then getting the
   *   blob, then uploading the file with the same function as regular recordings used seems to keep things clean
   * while allowing a new note to be created every time.
   *
   */

  const importedAudioUrl = audioUrl;

  fetch(importedAudioUrl)
    .then((response) => response.blob())
    .then((blob) => {
      uploadToCloud({ blob, createNewNote });
    })
    .catch(() => {
      alert(
        "error",
        "An error occurred. Please try again later or contact Scribenote Support.",
      );
    });
}
